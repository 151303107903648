import React, { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

function PrivateRouter({ children }: Props) {
  const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
  // if (!isAuthenticated && !isAuthenticating) return <Navigate to='/'/>
  // return children

  return !isAuthenticating ? (
    isAuthenticated ? (
      children
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <div>Loading...</div>
  );
}

export default PrivateRouter;
