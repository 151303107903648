import React, { useState, useEffect, useContext } from "react";
import { get, update } from "../../../../Utils/Functions/Methods";
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import DesignFormData from "../../../../Components/DesignRequestForm";
import { AuthContext } from "../../../../Context/AuthContext";
import { StateContext } from "../../../../Context/StateContext";
import { useNavigate } from "react-router";
import "./style.css";

function Index({ refreshData, setRefreshData }) {
  const [individualJobData, setIndividualJobData] = useState(null);
  const [popupMenu, setPopupMenu] = useState(false);
  const [completedJob, setCompletedJob] = useState(null);
  const [siteDetailsModal, setSiteDetailsModal] = useState(false);
  const [cadUser, setCadUser] = useState(null);
  const [individualSitePlan, setIndividualSitePlan] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const { refreshDesignPending, setRefreshDesignPending } =
    useContext(AuthContext);
  const { setIndividualJobDetails } = useContext(StateContext);
  const navigate = useNavigate();

  useEffect(() => {
    get(`/admin/all_jobs?job_status=completed`)
      .then((response) => {
        setCompletedJob(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, [refreshData]);

  const viewJobData = async (data) => {
    setPopupMenu(true);
    setIndividualJobData(data);

    await get(`/cad/users/${data?.assigned_to}`)
      .then((response) => {
        setCadUser(response[1]);
      })
      .catch((error) => {
        console.log("cad User Data Fetch Error", error);
      });
  };

  const viewSitePlan = async (data) => {
    setSiteDetailsModal(true);

    await get(`/admin/sitePlanRequests/${data.site_key}`).then((response) => {
      setIndividualSitePlan(response[1][0]);
    });

    await get(`/admin/users/${individualSitePlan?.created_by}`)
      .then((response) => {
        setCustomerData(response[1]);
      })
      .catch((error) => {
        console.log("CustomerDataError", error);
      });
  };

  return (
    <>
      {/* <div className="card-accept1">
        <div className="head-1">Completed</div>
        <div className="cards-new">
          {completedJob?.map((data) => {
            return (
              <div className="d-flex cards-1">
                <div className="id-heading">{data.job_id}</div>
                <div className="id-name">{data.job_status}</div>
                <button
                  className="button-view1"
                  onClick={() => {
                    setIndividualJobDetails(data)
                    navigate("/designdetails")
                  }}
                >
                  View
                </button>
              </div>
            );
          })}
        </div>
      </div> */}

      <div className="dashboard-table">
        <table>
          <thead>
            <tr style={{ backgroundColor: "#E4DBDB" }}>
              <th>Job ID</th>
              <th>Site name</th>
              <th>Project Status</th>
              <th>File Status</th>
            </tr>
          </thead>

          <tbody className=" tables-contents">
            {completedJob?.map((data) => {
              return (
                <tr className="rows " onClick={() => {}}>
                  <td
                    style={{
                      color: "#000",
                    }}
                  >
                    {data.job_id}
                  </td>
                  <td
                    style={{
                      color: "#3D5AD0",
                    }}
                  >
                    {data.site_details.site_name}
                  </td>
                  <td
                    style={{
                      color: "#4CAF50",
                    }}
                  >
                    {data.job_status}
                  </td>
                  <td
                    style={{
                      color: "#4CAF50",
                    }}
                  >
                    {data.file_upload_status}
                  </td>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#6474E5",
                      border: "1px solid black",
                      float: "right",
                      marginTop: "1px",
                    }}
                    className="btn-light view-overlay"
                    onClick={() => {
                      navigate(`/jobDetails/${data?.job_id}`);
                      setIndividualJobDetails(data);
                    }}
                  >
                    View
                  </Button>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <div className="d-flex accepting-card">
        <div className="">Job id</div>
        <div className="">Site Name</div>
        <div className="">Project Status</div>
        <div className="">File Status</div>
      </div>

      {completedJob?.map((data) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex accepting-card1">
              <div className="">{data.job_id}</div>
              <div className="">{data.site_details.name}</div>
              <div className="">{data.job_status}</div>
              <div className="">{data.file_upload_status}</div>
            </div>
            <div
              onClick={() => {
                handleShow();
                setIndividualData(data);
              }}
              className="view-btn-project"
            >
              View
            </div>
          </div>
        );
      })} */}

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={popupMenu}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Job Details
          </Modal.Title>
          <CloseButton onClick={() => setPopupMenu(false)} />
        </Modal.Header>
        <ModalBody>
          {individualJobData ? (
            <div className="d-flex">
              <div>
                <div className="d-flex number-content">
                  <div className="contents-view">Site Key:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.site_key}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Assinged To:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.assigned_to}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Assinged By: </div>
                  <div className="ms-2 mail-main">
                    {individualJobData.assigned_by}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Modified By:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.modified_by}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Created At:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.created_at}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Modified At:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.modified_at}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Job Status:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.job_status}
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="view-content"
                  onClick={() => viewSitePlan(individualJobData)}
                >
                  View
                </button>
              </div>
            </div>
          ) : (
            // <div className="mt-1">
            //         {/* Home Type */}
            //         <Row>
            //           <Col sm={6} className="mar-left">
            //             <Form.Label className="req-form fw-bold mx-5">Site Key:</Form.Label>
            //             <div
            //               className="w-75 mx-5  p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.site_key}
            //             </div>
            //           </Col>
            //           {/* 1500sqft */}
            //           <Col sm={6}>

            //             <Form.Label className="req-form fw-bold">Assinged To:</Form.Label>
            //             <div
            //               className="w-75  p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.assigned_to}

            //             </div>
            //           </Col>
            //         </Row>

            //         {/* bhk_type */}
            //         <Row>
            //           <Col sm={6} className="mar-left mt-3">
            //             <Form.Label className="req-form fw-bold mx-5">Assinged By:</Form.Label>
            //             <div
            //               className="w-75 mx-5 p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.assigned_by}
            //             </div>
            //           </Col>
            //           {/*  type */}
            //           <Col sm={6} className=" mt-3">
            //             <Form.Label className="req-form fw-bold">Modified By:</Form.Label>
            //             <div
            //               className="w-75 mt-1 p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.modified_by}
            //             </div>
            //           </Col>
            //         </Row>

            //         {/* No. Floors */}
            //         <Row>
            //           <Col sm={6} className="mar-left mt-3">
            //             <Form.Label className="req-form fw-bold mx-5">Created At:</Form.Label>
            //             <div
            //               className="w-75 mx-5 mt-1 p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.created_at}
            //             </div>
            //           </Col>
            //           {/* Floor type */}
            //           <Col sm={6} className=" mt-3">
            //             <Form.Label className="req-form fw-bold">Modified At:</Form.Label>
            //             <div
            //               className="w-75 mt-1 p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.modified_at}
            //             </div>
            //           </Col>
            //         </Row>

            //         {/* No. of windows */}
            //         <Row>
            //           <Col sm={6} className="mar-left mt-3">
            //             <Form.Label className="req-form fw-bold  mx-5">Job Status:</Form.Label>
            //             <div
            //               className="w-75 mx-5 mt-1 p-1 shadow-sm rounded"
            //               style={{ border: "solid 1px #A89F9F" }}
            //             >
            //               {individualJobData.job_status}
            //             </div>
            //           </Col>
            //         </Row>

            //       </div>

            ""
          )}
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button onClick={() => setPopupMenu(false)}>Close</Button>
          {/* <Button
            variant="outline-success"
            onClick={() => {
              accepted(individualJobData);
            }}
            id="Accepted"
          >
            Assign Job
          </Button>{" "}
          <Button
            variant="outline-danger"
            onClick={() => decline(individualJobData)}
          >
            Decline Job
          </Button>{" "} */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={siteDetailsModal}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Job Details
          </Modal.Title>
          <CloseButton onClick={() => setSiteDetailsModal(false)} />
        </Modal.Header>
        <ModalBody>
          <DesignFormData
            individualData={individualJobData}
            customerData={null}
          />
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button onClick={() => setSiteDetailsModal(false)}>Close</Button>
          {/* <Button
            variant="outline-success"
            onClick={() => {
              accepted(individualData);
            }}
            id="Accepted"
          >
            Assign Job
          </Button>{" "}
          <Button
            variant="outline-danger"
            onClick={() => decline(individualData)}
          >
            Decline Job
          </Button>{" "} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
