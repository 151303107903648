import React, { useState } from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  usePreventScroll(isVisible);
  return (
    <div>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div>Notification Page</div>
      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </div>
  );
}

export default Index;
