import React from "react";

function Phone() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4688 12.125C10.125 12.125 8.81521 11.8254 7.53938 11.2262C6.26312 10.6275 5.13292 9.83583 4.14875 8.85125C3.16417 7.86708 2.3725 6.73688 1.77375 5.46062C1.17458 4.18479 0.875 2.875 0.875 1.53125C0.875 1.34375 0.9375 1.1875 1.0625 1.0625C1.1875 0.9375 1.34375 0.875 1.53125 0.875H4.0625C4.20833 0.875 4.33854 0.921875 4.45313 1.01562C4.56771 1.10937 4.63542 1.22917 4.65625 1.375L5.0625 3.5625C5.08333 3.70833 5.08083 3.84104 5.055 3.96062C5.02875 4.08062 4.96875 4.1875 4.875 4.28125L3.35938 5.8125C3.79688 6.5625 4.34646 7.26562 5.00812 7.92188C5.66937 8.57812 6.39583 9.14583 7.1875 9.625L8.65625 8.15625C8.75 8.0625 8.8725 7.99208 9.02375 7.945C9.17458 7.89833 9.32292 7.88542 9.46875 7.90625L11.625 8.34375C11.7708 8.375 11.8906 8.44521 11.9844 8.55437C12.0781 8.66396 12.125 8.79167 12.125 8.9375V11.4688C12.125 11.6563 12.0625 11.8125 11.9375 11.9375C11.8125 12.0625 11.6563 12.125 11.4688 12.125ZM2.76562 4.625L3.79688 3.59375L3.53125 2.125H2.14062C2.19271 2.55208 2.26562 2.97396 2.35938 3.39062C2.45312 3.80729 2.58854 4.21875 2.76562 4.625ZM8.35938 10.2188C8.76562 10.3958 9.17979 10.5365 9.60187 10.6406C10.0235 10.7448 10.4479 10.8125 10.875 10.8438V9.46875L9.40625 9.17188L8.35938 10.2188Z"
        fill="#3D5AD0"
      />
    </svg>
  );
}

export default Phone;
