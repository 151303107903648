import React from 'react'

function Mail() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 14H18V11H15.45C15.1 11.6167 14.6167 12.104 14 12.462C13.3833 12.8207 12.7167 13 12 13C11.3 13 10.6417 12.8207 10.025 12.462C9.40833 12.104 8.91667 11.6167 8.55 11H6V14ZM12 11C12.5667 11 13.0417 10.804 13.425 10.412C13.8083 10.0207 14 9.55 14 9H18V2H6V9H10C10 9.55 10.196 10.0207 10.588 10.412C10.9793 10.804 11.45 11 12 11ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H6ZM2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V4H2V18H16V20H2Z" fill="#1C1B1F"/>
</svg>

  )
}

export default Mail