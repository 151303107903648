import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import usePreventScroll from "../../CustomHooks/PreventScroll";
import { get, post } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
// import "./style.css";
import { useParams } from "react-router";

function Index() {
    const [isVisible, setIsVisible] = useState(false);
    const params = useParams();
    usePreventScroll(isVisible);
    const { userAttributes } = useContext(AuthContext);
    const [customerQueries, setCustomerQueries] = useState(null);
    const chatWindowRef = useRef(null);
    const cad_support_id = params.id;
    console.log(cad_support_id)

    const [ticketDetails, setTicketDetails] = useState(null);
    const [chatDetails, setChatDetails] = useState(null);
    const [messageInput, setMessageInput] = useState("");
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        get(

            `/admin/${userAttributes?.sub}/cad_support_details/${cad_support_id}`
        )
            .then((response) => {
                console.log(response[1][0]);
                setTicketDetails(response[1][0].cad_support_details);
                setChatDetails(response[1][0].comments);
            })
            .catch((err) => console.log(err));
    }, [refresh]);

    const handleSendComment = (comment) => { };
    console.log("ffffffff", userAttributes?.sub);
    const handleMessageSubmit = (event) => {
        event.preventDefault();
        const data = {
            // customer_support_id: ticketDetails.id,
            parent_id: 0,
            comment: messageInput,
            tag: "Admin",
        };
        console.log(data);

        post(`/admin/${userAttributes?.sub}/cad_support_comment/${cad_support_id}`, data)
            .then((response) => {
                console.log(response);
                setRefresh(!refresh);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }, [refresh]);

    return (
        <>
            <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
            <div className="w-100 d-flex flex-column align-items-center justify-content-center gap-1">
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Id</div>
                    <div>{ticketDetails?.id}</div>
                </div>
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Name</div>
                    <div>{ticketDetails?.created_by_username}</div>
                </div>
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Email</div>
                    <div>{ticketDetails?.email}</div>
                </div>
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Mobile</div>
                    <div>{ticketDetails?.mobile}</div>
                </div>
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Status</div>
                    <div>{ticketDetails?.status}</div>
                </div>
                <div className="ticket-details-box">
                    <div className="ticket-detail-tag">Reason</div>
                    <div>{ticketDetails?.reason_for_support}</div>
                </div>
            </div>
            <div className="chat-parent-container">
                <div className="chat-container">
                    <div className="chat-window" ref={chatWindowRef}>
                        {chatDetails?.map((message) => (
                            <div
                                key={message?.id}
                                className={`message ${message?.tag === "Admin" ? "sent" : "received"
                                    }`}
                            >
                                <div className="message-header">
                                    <div className="message-author">{message?.created_by}</div>
                                    <div className="message-timestamp">
                                        {message?.created_at.slice(0, 16)}
                                    </div>
                                </div>
                                <div className="message-body">{message?.comment}</div>
                            </div>
                        ))}
                    </div>
                    <form onSubmit={handleMessageSubmit}>
                        <input
                            type="text"
                            value={messageInput}
                            onChange={(event) => setMessageInput(event.target.value)}
                            placeholder="Type your message here..."
                        />
                        <button disabled={!messageInput} type="submit">
                            Send
                        </button>
                    </form>
                </div>
            </div>
            <Sidebar
                className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
                    }`}
                sidebarOnClickClose={() => setIsVisible(false)}
            />
        </>
    );
}

export default Index;
