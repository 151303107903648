import React, { useContext, useState } from "react";
import "./style.css";
import Sidebaricon from "../../Assets/Icons/sidebar";
import ProfileIcon from "../../Assets/Icons/profile";
import NotificationIcon from "../../Assets/Icons/notification";
import MailIcon from "../../Assets/Icons/mail";
import { useNavigate } from "react-router";
import Logo from "../../Assets/Logo/Consdroid-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import Search from '../../Assets/Icons/search'

interface Props {
  sidebarOnClickOpen: () => void;
  
}

function Index({ sidebarOnClickOpen }: Props) {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);
  return (
    <div className="navbar-container">
      <div className="navbar-sidebar-icon" onClick={sidebarOnClickOpen}>
        <Sidebaricon />
      </div>
      <div className="navbar-search">
        <input className="navbar-search-input" placeholder="Search"/>
        <div className="navbar-search-icon">
        <Search/>
        </div>
      </div>
      <div className="navbar-rightside-content">
        <div className="navbar-mail">
          <MailIcon />
        </div>
        <div
          className="navbar-notification"
          onClick={() => navigate("/notification")}
        >
          <NotificationIcon />
        </div>
        <div className="navbar-profile" onClick={() => navigate("/profile")}>
          <ProfileIcon />
        </div>
      </div>
    </div>
  );
}

export default Index;
