import React from 'react'

function Mail2() {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 10.5C1.15625 10.5 0.862083 10.3777 0.6175 10.1331C0.3725 9.88813 0.25 9.59375 0.25 9.25V1.75C0.25 1.40625 0.3725 1.11208 0.6175 0.8675C0.862083 0.6225 1.15625 0.5 1.5 0.5H11.5C11.8438 0.5 12.1381 0.6225 12.3831 0.8675C12.6277 1.11208 12.75 1.40625 12.75 1.75V9.25C12.75 9.59375 12.6277 9.88813 12.3831 10.1331C12.1381 10.3777 11.8438 10.5 11.5 10.5H1.5ZM6.5 6.125L1.5 3V9.25H11.5V3L6.5 6.125ZM6.5 4.875L11.5 1.75H1.5L6.5 4.875ZM1.5 3V1.75V9.25V3Z" fill="#3D5AD0"/>
</svg>

  )
}

export default Mail2
