import React from "react";

function notification() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17V15H2V8C2 6.58333 2.425 5.34567 3.275 4.287C4.125 3.229 5.2 2.53333 6.5 2.2V1.5C6.5 1.08333 6.646 0.729333 6.938 0.438C7.22933 0.146 7.58333 0 8 0C8.41667 0 8.77067 0.146 9.062 0.438C9.354 0.729333 9.5 1.08333 9.5 1.5V2.2C9.91667 2.3 10.3083 2.44167 10.675 2.625C11.0417 2.80833 11.3833 3.03333 11.7 3.3L10.275 4.725C9.95833 4.49167 9.60833 4.31267 9.225 4.188C8.84167 4.06267 8.43333 4 8 4C6.9 4 5.95833 4.39167 5.175 5.175C4.39167 5.95833 4 6.9 4 8V15H12V12H14V15H16V17H0ZM8 20C7.45 20 6.97933 19.8043 6.588 19.413C6.196 19.021 6 18.55 6 18H10C10 18.55 9.80433 19.021 9.413 19.413C9.021 19.8043 8.55 20 8 20ZM15 11V8H12V6H15V3H17V6H20V8H17V11H15Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}

export default notification;
