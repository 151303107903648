import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../Context/AuthContext";
import "./style.css";

function Index() {
  const navigate = useNavigate();

  const initialValues = { email: "", password: "" };
  const [values, setValues] = useState(initialValues);
  const [emailError, setEmailError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [codeIsSent, setCodeIsSent] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const { forgotPassword } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    console.table([e.target.value, emailRegex.test(values?.email)]);
    if (e.target.name == "email")
      if (!values?.email) {
        setEmailError("Email is Required");
      } else if (!emailRegex.test(values?.email)) {
        setEmailError("Please Enter a Valid Email");
      } else {
        setEmailError("");
      }
  };

  const handleRequest = async () => {
    if (values?.email !== "") {
      await forgotPassword
        .requestCode(values?.email)
        .then((_) => {
          // console.log("OTP sent > ", _);
          setCodeIsSent(true);
          navigate("/setpassword");
        })
        .catch((err) => console.log("error > ", err));
    }
  };
  useEffect(() => {
    setIsDisabled(!emailRegex.test(values?.email));
  }, [values]);

  return (
    <div className="forgotpass-container">
      <div className="forgotpass-box">
        <div className="login-box-title">Forgot Password</div>
        <div className="login-box-input">
          <input
            name="email"
            placeholder="Email"
            className="login-box-input-field"
            onChange={handleChange}
            value={values?.email}
          ></input>
          <p className="forgotpass-auth-error">{emailError}</p>
        </div>
        <button
          className="forgotpass-box-btn"
          disabled={isDisabled}
          onClick={() => {
            handleRequest();
          }}
        >
          <div>Send OTP</div>
        </button>
      </div>
    </div>
  );
}

export default Index;
