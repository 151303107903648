import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./UsePagination";
import "./style.css";

export default function Pagination(props) {
  console.log("props", props);
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        <li
          className={classnames("pagination-item-arrow", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          {"<"}
          {/* <div className="arrow left"></div> */}
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots"></li>;
          }

          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames("pagination-item-arrow", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          {" "}
          {">"}
          {/* <div className="arrow right" /> */}
        </li>
      </ul>
    </>
  );
}
