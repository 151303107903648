import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { Button } from "react-bootstrap";

import Phone from "../../Assets/Icons/phone";
import Mail from "../../Assets/Icons/mail2";
import Location from "../../Assets/Icons/location";
import { get } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import Pagination from "../../Components/Pagination";
import usePreventScroll from "../../CustomHooks/PreventScroll";
import { useNavigate } from "react-router";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  const { userAttributes } = useContext(AuthContext);
  const [userList, setUserList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 50;
  usePreventScroll(isVisible);
  const navigate = useNavigate();

  useEffect(() => {
    get(`/admin/user/${userAttributes?.sub}/all_user`)
      .then((response) => {
        setUserList(response[1]);

      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {/* <th>User Key</th> */}
              <th>Location</th>


              <th>Subscription status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userList?.map((data) => {
              return (
                <tr style={{ cursor: "pointer" }} key={data?.user_key}>
                  <td>{data?.firstname + " " + data?.lastname}</td>

                  <td>{data?.email}</td>
                  {/* <td>{data?.user_key}</td> */}
                  <td>{data?.location}</td>
                  <td>{data?.is_subscribed}</td>

                  <td onClick={() => navigate(`${data?.user_key}`)}>
                    View More
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {userList && (
          <div className="p-4">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={userList.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

      <Sidebar
        className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
          }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
