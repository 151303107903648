import React, { useContext, useState } from "react";
import "./style.css";
import CloseButton from "../../Assets/Icons/closebtn";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import Logo from "../../Assets/Icons/logo";
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  sidebarOnClickClose: () => void;
}

function Index(props: Props) {
  const navigate = useNavigate();
  const { userAttributes, signOut } = useContext(AuthContext);
  const [showSubSideBar, setShowSubSideBar] = useState(false);
  const [currentButton, setcurrentButton] = useState(window.location.pathname);

  return (
    <div {...props}>
      <div className="sidebar-top">
        <div className="sidebar-close-btn" onClick={props.sidebarOnClickClose}>
          <CloseButton />
        </div>
        <div className="sidebar-logo">
          <Logo />
        </div>

        {/* <div
          className={`sidebar-path ${
            showSubSideBar ? "subSidebarBgSelected" : ""
          }`}
        >
          <div
            onClick={() => {
              setShowSubSideBar(!showSubSideBar);
            }}
          >
            {showSubSideBar ? "-" : "+"} Cad Designers
            {showSubSideBar ? (
              <div className="cad-designer-sub-sidebar">
                <div onClick={() => navigate("/memberapproval")}>
                  Member Approval
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div> */}
        <div className="sidebar-menu-label">
          <div
            className={`${currentButton == "/dashboard"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </div>
          <div
            className={`${currentButton == "/users" ? "selectedClass" : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/users");
            }}
          >
            Users
          </div>
          <div
            className={`${currentButton == "/memberapproval"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/memberapproval");
            }}
          >
            CAD Designers
          </div>
          <div
            className={`${currentButton.includes("/designRequest")
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/designRequests/pending");
            }}
          >
            Design Requests
          </div>
          <div
            className={`${currentButton == "/preRegistration"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/preRegistration");
            }}
          >
            Pre-Registered Users
          </div>
          <div
            className={`${currentButton == "/customerQueries"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/customerQueries");
            }}
          >
            Customer Queries
          </div>
          <div
            className={`${currentButton == "/cadQueries"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/cadQueries");
            }}
          >
            Cad Queries
          </div>
          <div
            className={`${currentButton == "/customertable"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/customertable");
            }}
          >
            Customer Table
          </div>
          <div
            className={`${currentButton == "/cadUsers"
              ? "selectedClass"
              : "unSelectedClass"
              }`}
            onClick={() => {
              navigate("/cadUsers");
            }}
          >
            Cad User Payment
          </div>
        </div>
      </div>
      <div className="sidebar-footer">
        <div
          onClick={() => {
            signOut();
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
}

export default Index;
