import React, { useState, useEffect, useContext } from "react";
import { get, update } from "../../../../Utils/Functions/Methods";
import { Button, CloseButton, Modal, ModalBody } from "react-bootstrap";
import DesignFormData from "../../../../Components/DesignRequestForm";
import { AuthContext } from "../../../../Context/AuthContext";
import { useNavigate } from "react-router";
import { StateContext } from "./../../../../Context/StateContext/index";

function Index({ refreshData, setRefreshData }) {
  const { userAttributes, refreshDesignPending, setRefreshDesignPending } =
    useContext(AuthContext);
  const [pendingJob, setPendingJob] = useState(null);
  const [individualJobData, setIndividualJobData] = useState(null);
  const [popupMenu, setPopupMenu] = useState(false);
  const [siteDetailsModal, setSiteDetailsModal] = useState(false);
  const [cadUser, setCadUser] = useState(null);
  const [individualSitePlan, setIndividualSitePlan] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [reAssignJobModal, setReAssignJobModal] = useState(false);
  const [alloactedCAD, setAllocatedCAD] = useState("");
  const [cadUsers, setCadUsers] = useState(null);
  const navigate = useNavigate();

  const { setIndividualJobDetails } = useContext(StateContext);

  useEffect(() => {
    get(`/admin/all_jobs?job_status=pending`)
      .then((response) => {
        setPendingJob(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, [refreshData]);

  // const handleShow = () => {
  //   setPopupMenu(true);
  //   console.log("open");
  // };

  const viewSitePlan = async (data) => {
    setSiteDetailsModal(true);

    await get(`/admin/sitePlanRequests/${data.site_key}`).then((response) => {
      setIndividualSitePlan(response[1][0]);
    });

    await get(`/admin/users/${individualSitePlan?.created_by}`)
      .then((response) => {
        setCustomerData(response[1]);
      })
      .catch((error) => {
        console.log("CustomerDataError", error);
      });
  };

  const reassignJob = async (data) => {
    const tempData = {
      ...data,
      assigned_to: alloactedCAD,
      modified_by: userAttributes?.sub,
      job_status: "pending",
    };
    await update(`/admin/jobTable/${data.job_id}`, tempData)
      .then(([status, res]) => {
        if (status === 200) {
          setRefreshData(!refreshData);
          setPopupMenu(false);
          alert("Success");
        } else {
          alert("Something went wrong");
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };
  console.log(pendingJob)

  return (
    <>
      {/* <div className="card-accept1">
        <div className="head-1">Pending</div>
        <div className="cards-new">
          {pendingJob?.map((data) => {
            return (
              <div className="d-flex cards-1">
                <div className="id-heading">{data.job_id}</div>
                <div className="id-name">{data.job_status}</div>
                <div className="">
                  <button
                    className="button-view1"
                    // onClick={() => {
                    //   viewJobData(data);
                    // }}
                    onClick={() => {
                      // navigate("/designpendingrequests");
                      setIndividualJobDetails(data)
                      navigate("/designdetails")
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

      <div className="dashboard-table">
        <table>
          <thead>
            <tr style={{ backgroundColor: "#E4DBDB" }}>
              <th>Job ID</th>
              <th>Site name</th>
              <th>Project Status</th>
              <th>File Status</th>
            </tr>
          </thead>

          <tbody>
            {pendingJob?.map((data) => {
              return (
                <tr className="rows">
                  <td>{data.job_id}</td>
                  <td>{data.site_details.site_name}</td>
                  <td>{data.job_status}</td>
                  <td>{data.file_upload_status}</td>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "red",
                      border: "1px solid black",
                      float: "right",
                      marginTop: "1px",
                    }}
                    className="btn-light view-overlay"
                    onClick={() => {
                      navigate(`/jobDetails/${data?.job_id}`);
                      setIndividualJobDetails(data);
                    }}
                  >
                    View
                  </Button>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={popupMenu}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Job Details
          </Modal.Title>
          <CloseButton
            onClick={() => {
              setPopupMenu(false);
              setAllocatedCAD("");
              setReAssignJobModal(false);
            }}
          />
        </Modal.Header>
        <ModalBody>
          {individualJobData ? (
            <div className="d-flex">
              <div>
                <div className="d-flex number-content">
                  <div className="contents-view">Assinged To:</div>
                  <div className="ms-2 mail-main">
                    {cadUser?.firstname + " " + cadUser?.lastname}
                  </div>
                </div>
                <div className="d-flex number-content">
                  <div className="contents-view">Assinged By: </div>
                  <div className="ms-2 mail-main">
                    {individualJobData.assigned_by}
                  </div>
                </div>

                <div className="d-flex number-content">
                  <div className="contents-view">Created At:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.created_at}
                  </div>
                </div>

                <div className="d-flex number-content">
                  <div className="contents-view">Job Status:</div>
                  <div className="ms-2 mail-main">
                    {individualJobData.job_status}
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex flex-column gap-3 ">
                  <div
                    className="view-content"
                    onClick={() => viewSitePlan(individualJobData)}
                  >
                    View Site Details
                  </div>
                  <div
                    className="view-content"
                    onClick={() => setReAssignJobModal(!reAssignJobModal)}
                  >
                    Re-Assign Job
                  </div>
                  <div
                    className="view-content"
                    onClick={() => viewSitePlan(individualJobData)}
                  >
                    View Project Files
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          {reAssignJobModal ? (
            <>
              <div>
                <select
                  className="form-select"
                  name="CAD-Designers-List"
                  id="CAD-Designers-List"
                  onChange={(e) => {
                    setAllocatedCAD(e?.target.value);
                  }}
                >
                  <option value="">Please Select Cad User</option>
                  {cadUsers?.map((data) => {
                    return (
                      <option value={data?.user_key}>
                        {data?.firstname + " " + data?.lastname}
                      </option>
                    );
                  })}
                </select>
              </div>
              {alloactedCAD != "" ? (
                <Button
                  variant="outline-success"
                  onClick={() => {
                    reassignJob(individualJobData);
                  }}
                  id="Accepted"
                >
                  Re-Assign Job
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Button
            style={{ border: "1px solid red" }}
            variant="light text-danger "
            onClick={() => setPopupMenu(false)}
          >
            Close
          </Button>

          {/* <Button
            variant="outline-success"
            onClick={() => {
              accepted(individualJobData);
            }}
            id="Accepted"
          >
            Assign Job
          </Button>{" "}
          <Button
            variant="outline-danger"
            onClick={() => decline(individualJobData)}
          >
            Decline Job
          </Button>{" "} */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={siteDetailsModal}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Site Details
          </Modal.Title>
          <CloseButton onClick={() => setSiteDetailsModal(false)} />
        </Modal.Header>
        <ModalBody>
          <DesignFormData
            individualData={individualSitePlan}
            customerData={customerData}
          />
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button onClick={() => setSiteDetailsModal(false)}>Close</Button>
          {/* <Button
            variant="outline-success"
            onClick={() => {
              accepted(individualData);
            }}
            id="Accepted"
          >
            Assign Job
          </Button>{" "}
          <Button
            variant="outline-danger"
            onClick={() => decline(individualData)}
          >
            Decline Job
          </Button>{" "} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
