import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import "./style.css";
import { get, post, update } from "../../Utils/Functions/Methods";
import Pagination from "../../Components/Pagination";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 30;
  usePreventScroll(isVisible);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   const data = customerDetails.slice(firstPageIndex, lastPageIndex);
  //   console.log(data);
  //   return customerDetails.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage, customerDetails]);
  // console.log(currentTableData);
  console.log(currentPage);
  // useEffect(() => {
  //     const storeNotes = localStorage.getItem('valuess');
  //     if (storeNotes) {
  //         setValue(storeNotes);
  //     }
  // }, []);

  // const handleEdit = (event) => {
  //     setValue(event.target.value);
  // };

  // const handleKeyPress = (event) => {
  //     if (event.key === "Enter") {
  //         setIsEditable(false);
  //     }
  // };

  const handleSelect = (e, id) => {
    console.log(e.target.value, id);
    update(`/admin/crm/customer_data/${id}`, { response: e.target.value })
      .then((res) => console.log("Success"))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    get(`/admin/crm/customer_data`)
      .then((response) => {
        console.log(response[1]);
        setCustomerDetails(response[1]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Customer Type</th>
              <th>Responses</th>
            </tr>
          </thead>
          <tbody>
            {customerDetails?.map((data) => {
              return (
                <tr key={data?.id}>
                  <td>{data?.name}</td>
                  <td>{data?.email}</td>
                  <td>{data?.phone}</td>
                  <td>{data?.address}</td>
                  <td>{data?.type}</td>
                  {data?.response != null ? (
                    <td>{data?.response}</td>
                  ) : (
                    <select
                      className="form-control"
                      value={value}
                      onChange={(e) => handleSelect(e, data?.id)}
                    >
                      <option value="0">--Please choose an option--</option>
                      <option value="Mail Sent">Mail sent</option>
                      <option value="Mail Not sent">Mail Not sent</option>
                      <option value=" Waiting for reply">
                        {" "}
                        Waiting for reply
                      </option>
                    </select>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {customerDetails && (
          <div className="p-4">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={customerDetails.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
