import React, { useEffect, useState } from 'react';
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { get } from '../../Utils/Functions/Methods';
import { useNavigate } from 'react-router';

export default function Index() {
    const [isVisible, setIsVisible] = useState(false);
    const [cadUsers, setCadUsers] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        get(`/admin/user/{user_key}/all_cad_user`)
            .then((response) => {
                setCadUsers(response[1]);
            })
            .catch((error) => {
                console.log("profile fetch error", error);
            });
    }, []);
    console.log("fghj", cadUsers)
    return (
        <>
            <div>
                <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />

            </div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>

                            <th>District</th>

                        </tr>
                    </thead>
                    <tbody>
                        {cadUsers?.map((data, index) => {
                            return (
                                <tr
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(`/cadBankDetails/${data?.user_key}`)
                                    }
                                >
                                    <td>{data?.first_name + " " + data?.last_name}</td>
                                    <td>{data?.phone_number}</td>
                                    <td>{data?.email}</td>
                                    <td>{data?.district}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Sidebar
                className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
                    }`}
                sidebarOnClickClose={() => setIsVisible(false)}
            />
        </>
    )
}
