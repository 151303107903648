import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import "./style.css";
import usePreventScroll from "../../CustomHooks/PreventScroll";
import { get } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  usePreventScroll(isVisible);
  const { userAttributes } = useContext(AuthContext);
  const [customerQueries, setCustomerQueries] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    get(`/admin/${userAttributes?.sub}/customerQueries`)
      .then((response) => {
        console.log(response);
        setCustomerQueries(response[1]);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div className="">
        <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />

        <table className="table">
          <thead>
            <tr style={{ backgroundColor: "#DDD4D4" }}>
              <th>Ticket ID</th>
              <th>Created At</th>
              <th>email</th>
              <th>Name</th>
              <th>Topic</th>
              <th>Comments</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {customerQueries?.map((data) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/customerChat/${data?.customer_support_id}`)
                  }
                >
                  <td>{data?.ticket_no}</td>
                  <td>{data?.created_at}</td>
                  <td>{data?.email}</td>
                  <td>{data?.firstname + " " + data?.lastname}</td>
                  <td>{data?.reason_for_support}</td>
                  <td>{data?.comments}</td>
                  <td>{data?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
