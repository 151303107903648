import React, { useContext } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "../Pages/Main/Login";
import Signup from "../Pages/Main/Signup";
import Dashboard from "../Pages/Dashboard";
import { AuthContext } from "../Context/AuthContext";
import PrivateRouter from "./privateRouter";
import Profile from "../Pages/Profile";
import Notification from "../Pages/Notification";
import Support from "../Pages//Support";
import Settings from "../Pages/Settings";
import ForgotPassword from "../Pages/Main/ForgotPassword";
import SetPassword from "../Pages/Main/ForgotPassword/SetPassword";
import MemberApproval from "../Pages/MemberApproval";
import DesignRequest from "../Pages/DesignRequest";
import DesignPendingRequest from "../Pages/DesignRequest/DesignAccepted/PendingCard/DesignPending";
import DesignAcceptedDetails from "../Pages/DesignAcceptedDetails";
import CustomerQuery from "../Pages/CustomerQuery";
import CadQuery from "../Pages/CadQuery";
import CustomerTable from "../Pages/CustomerTable";
import Response from "../Pages/CustomerTable/Response";
import SitePlanDetails from "../Pages/sitePlanDetails";
import Users from "../Pages/Users";
import UserDetails from "../Pages/UserDetails";
import PreRegistration from "../Pages/PreRegistration";
import CustomerQueryChat from "../Pages/CustomerQueryChat";
import CadQueryChat from "../Pages/CadQueryChat";
import CadUserPayment from "../Pages/CadUserPayment";
import CadUserBankDetails from "../Pages/CadUserBankDetails";

export default function Index() {
  const { isAuthenticating, isAuthenticated } = useContext(AuthContext);
  return (
    <>
      {!isAuthenticating ? (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/setpassword" element={<SetPassword />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRouter>
                  <Dashboard />
                </PrivateRouter>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRouter>
                  <Profile />
                </PrivateRouter>
              }
            />
            <Route
              path="/Notification"
              element={
                <PrivateRouter>
                  <Notification />
                </PrivateRouter>
              }
            />
            <Route
              path="/support"
              element={
                <PrivateRouter>
                  <Support />
                </PrivateRouter>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRouter>
                  <Settings />
                </PrivateRouter>
              }
            />
            <Route
              path="/memberapproval"
              element={
                <PrivateRouter>
                  <MemberApproval />
                </PrivateRouter>
              }
            />
            <Route path="/designRequests/:designStatus">
              <Route
                path=""
                element={
                  <PrivateRouter>
                    <DesignRequest />
                  </PrivateRouter>
                }
              />
              <Route
                path=":jobStatus"
                element={
                  <PrivateRouter>
                    <DesignRequest />
                  </PrivateRouter>
                }
              />
            </Route>
            <Route
              path="/designpendingrequests"
              element={
                <PrivateRouter>
                  <DesignPendingRequest />
                </PrivateRouter>
              }
            />
            <Route
              path="/jobDetails/:jobId"
              element={
                <PrivateRouter>
                  <DesignAcceptedDetails />
                </PrivateRouter>
              }
            />
            <Route
              path="/sitePlanDetails/:projectKey/:siteKey"
              element={
                <PrivateRouter>
                  <SitePlanDetails />
                </PrivateRouter>
              }
            />
            <Route
              path="/customertable"
              element={
                <PrivateRouter>
                  <CustomerTable />
                </PrivateRouter>
              }
            />
            <Route
              path="/individualResponse"
              element={
                <PrivateRouter>
                  <Response />
                </PrivateRouter>
              }
            />
            <Route path="/users">
              <Route
                path=""
                element={
                  <PrivateRouter>
                    <Users />
                  </PrivateRouter>
                }
              />
              <Route
                path=":user_key"
                element={
                  <PrivateRouter>
                    <UserDetails />
                  </PrivateRouter>
                }
              />
            </Route>

            <Route
              path="/preRegistration"
              element={
                <PrivateRouter>
                  <PreRegistration />
                </PrivateRouter>
              }
            />
            <Route
              path="/customerQueries"
              element={
                <PrivateRouter>
                  <CustomerQuery />
                </PrivateRouter>
              }
            />
            <Route
              path="/cadQueries"
              element={
                <PrivateRouter>
                  <CadQuery />
                </PrivateRouter>
              }
            />
            <Route
              path="/customerChat/:id"
              element={
                <PrivateRouter>
                  <CustomerQueryChat />
                </PrivateRouter>
              }
            />
            <Route
              path="/cadChat/:id"
              element={
                <PrivateRouter>
                  <CadQueryChat />
                </PrivateRouter>
              }
            />
            <Route
              path="/cadUsers"
              element={
                <PrivateRouter>
                  <CadUserPayment />
                </PrivateRouter>
              }
            />
            <Route
              path="/cadBankDetails/:key"
              element={
                <PrivateRouter>
                  <CadUserBankDetails />
                </PrivateRouter>
              }
            />
          </Routes>
        </Router>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
}
