import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import { get, update, post } from "../../../Utils/Functions/Methods";
import { AuthContext } from "../../../Context/AuthContext";
import RejectedJobList from "../../../Components/RejectedJob-Siteplan";
import DesingFormData from "../../../Components/DesignRequestForm";
import "./style.css";
import { useNavigate } from "react-router";
function Index() {
  const [popupMenu, setPopupMenu] = useState(false);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [individualData, setIndividualData] = useState(null);
  const [accept, setAccept] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [result, setResult] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [cadUsers, setCadUsers] = useState(null);
  const [alloactedCAD, setAllocatedCAD] = useState("");
  const [customerData, setCustomerData] = useState(null);
  const navigate = useNavigate();

  const jobTable = {
    site_key: "",
    assigned_to: "",
    assigned_by: "",
    modified_at: "",
    modified_by: "",
    created_at: "",
    job_status: "",
    project_folder_key: "",
    file_upload_status: "pending",
  };

  const GenerateUniqueKey = () => {
    const date = new Date();
    const components = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];
    // setUniquekey(components.join(""));
    const uniquekey = components.join("");

    return uniquekey;
  };

  const { userAttributes } = useContext(AuthContext);

  useEffect(() => {
    get(`/admin/sitePlanRequests?request_status=pending`)
      .then((response) => {
        setData(response[1]);
        console.log("Anus", response[1])
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, [refreshData]);

  const accepted = async (data) => {
    const temp = { ...data, request_status: "accepted" };
    await update(`/admin/sitePlanRequests/${data.site_key}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          setAccept(true);
          setPopupName(data.name);
          setResult("Accepted");
          setRefreshData(!refreshData);
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));

    const tempData = {
      ...jobTable,
      assigned_to: alloactedCAD,
      site_key: individualData?.site_key,
      assigned_by: userAttributes?.sub,
      job_status: "pending",
      project_folder_key: GenerateUniqueKey(),
    };
    await post(`/admin/jobTable`, tempData)
      .then(([status, res]) => {
        if (status === 200) {
          setRefreshData(!refreshData);
          setPopupMenu(false);
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };
  const decline = async (data) => {
    const temp = { ...data, request_status: "rejected" };
    await update(`/admin/sitePlanRequests/${data.site_key}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          console.log("result success", res);
          setRefreshData(!refreshData);
          setPopupMenu(false);
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {/* <RejectedJobList /> */}
      {data?.map((data) => {
        return (
          <div className="memberApproval-card">
            <div className="member-name-details">
              <div className="member-profile-photo"></div>
              <div className="member-profile-name">
                {data.first_name + " " + data.last_name}
              </div>
            </div>
            <div className="member-contact-details-container">
              <div className="member-contact-detail-box">
                <div className="member-contact-icon"></div>
                <div className="member-contact-detail">{data.type}</div>
              </div>
              <div className="member-contact-detail-box">
                <div className="member-contact-icon"></div>
                <div className="member-contact-detail">{data.project_key}</div>
              </div>
              <div className="member-contact-detail-box">
                <div className="member-contact-icon"></div>
                <div className="member-contact-detail">{data.email}</div>
              </div>
            </div>

            <div className="memberApproval-response">
              {data?.is_paid === 1 ? (
                <Button
                  onClick={() => {
                    navigate(
                      `/sitePlanDetails/${data.project_key}/${data.site_key}`
                    );
                  }}
                >
                  View
                </Button>

              ) : <div className="text-danger fw-bold">Not yet Paid</div>}

            </div>
          </div>
        );
      })}

      {/* //!SITE PLAN REQUEST FROM CUSTOMER MODAL */}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={popupMenu}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Details
          </Modal.Title>
          <CloseButton
            onClick={() => {
              setPopupMenu(false);
              setAllocatedCAD("");
            }}
          />
        </Modal.Header>
        <ModalBody>
          <div className=" profile-content">
            <div className="d-flex profile">
              <div>
                <div className="member-profile-photo"></div>
              </div>
              <div className="d-flex all-proifle">
                <div className="d-flex profile-1">
                  <div>
                    <DesingFormData
                      individualData={individualData}
                      customerData={customerData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          {/* <Button onClick={() => setPopupMenu(false)}>Close</Button> */}
          <div className="designers-list">
            <select
              className="form-select"
              name="CAD-Designers-List"
              id="CAD-Designers-List"
              onChange={(e) => {
                setAllocatedCAD(e?.target.value);
              }}
            >
              <option value="">Please Select Cad User</option>
              {cadUsers?.map((data) => {
                return (
                  <option value={data?.user_key}>
                    {data?.firstname + " " + data?.lastname}
                  </option>
                );
              })}
            </select>
          </div>
          {alloactedCAD != "" ? (
            <Button
              variant="outline-success"
              onClick={() => {
                accepted(individualData);
              }}
              id="Accepted"
            >
              Assign Job
            </Button>
          ) : (
            <></>
          )}
          <Button
            variant="outline-danger"
            onClick={() => decline(individualData)}
          >
            Decline Job
          </Button>{" "}
        </Modal.Footer>
      </Modal>

      {/* //!JOB ID REJECTED MODAL */}
    </>
  );
}

export default Index;
