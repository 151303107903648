import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import FileIcon from "../../../Assets/Icons/fileIcon.svg";
import { useEffect, useState } from "react";
import { get } from "../../../Utils/Functions/Methods";
import { StateContext } from "../../../Context/StateContext";
import ThreeD from "./3D";
import TwoD from "./2D";
import Estimation from "./Estimation";
import { Modal } from "react-bootstrap";

function Index({ fileStatus, jobDetails }) {
  const [projectFiles, setProjectFiles] = useState(null);
  const { individualJobDetails } = useContext(StateContext);
  const [sectionDetails, setSectionDetails] = useState(null);
  const [designTemplate, setDesignTemplate] = useState<string>("3d");
  const [siteKey, setSiteKey] = useState("");
  const [download, setDownload] = useState<any>();
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    get(
      `/admin/jobProject/${individualJobDetails?.job_id}/jobProject/${individualJobDetails?.project_folder_key}`
    )
      .then((response) => {
        setSectionDetails(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, []);

  const switchTemplate = () => {
    switch (designTemplate) {
      case "3d":
        return <ThreeD setProjectFiles={setProjectFiles} />;
      case "2d":
        return <TwoD setProjectFiles={setProjectFiles} />;
      case "Estimation":
        return <Estimation setProjectFiles={setProjectFiles} />;
      default:
        return <ThreeD setProjectFiles={setProjectFiles} />;
    }
  };

  const getFilesFunction = async (data) => {
    await get(
      `/admin/jobProject/${jobDetails.job_id}/jobProject/${jobDetails.project_folder_key}/section/${sectionDetails.section_key}/files`
    )
      .then((response) => {
        setProjectFiles(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  };

  const handleFileDownload = async (data) => {
    const { file_name, path, extension } = data;

    await get(
      `/getUrl?file_name=${file_name}&path=${path}&extension=${extension}`
    )
      .then((response) => {
        setDownload(response[1]);
      })

      .catch((error) => {
        console.log("Files fetch error", error);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center flex-wrap folder-container gap-3">
        <div className="d-flex align-items-center justify-content-center flex-wrap folder-container gap-3">
          <div
            className={`d-flex align-items-center justify-content-center ${
              designTemplate == "3d"
                ? "files-design-template-btn-selected"
                : "files-design-template-btn"
            }`}
            onClick={() => {
              setDesignTemplate("3d");
              setProjectFiles(null);
            }}
          >
            3D
          </div>
          <div
            className={`d-flex align-items-center justify-content-center files-design-template-btn ${
              designTemplate == "2d"
                ? "files-design-template-btn-selected"
                : "files-design-template-btn"
            }`}
            onClick={() => {
              setDesignTemplate("2d");
              setProjectFiles(null);
            }}
          >
            2D
          </div>
          <div
            className={`d-flex align-items-center justify-content-center files-design-template-btn ${
              designTemplate == "Estimation"
                ? "files-design-template-btn-selected"
                : "files-design-template-btn"
            }`}
            onClick={() => {
              setDesignTemplate("Estimation");
            }}
          >
            Estimation
          </div>
        </div>
        {switchTemplate()}
      </div>
      {projectFiles ? (
        <div className="files-container">
          <div className="d-flex align-items-center flex-column gap-3">
            {projectFiles?.map((data) => {
              return (
                <div
                  onClick={() => {
                    handleFileDownload({
                      extension: data?.file_extension,
                      file_name: data?.file_key,
                      path: data?.file_path,
                    });
                    setShowDownloadModal(true);
                  }}
                  className="d-flex w-100 gap-2"
                >
                  <img src={FileIcon}></img>
                  <div className="w-100">{data.file_name}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}

      <Modal
        show={showDownloadModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDownloadModal(false)}
      >
        <Modal.Header className="d-flex align-items-center justify-content-center">
          <div>Are you Sure you want to Download</div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column upload-template-input-box">
            <a href={download?.downloadUrl}>Download</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Index;
