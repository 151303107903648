import React, { createContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
// import { useNavigate } from "react-router";
// import { Link, useHistory } from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [authState, setAuthState] = useState("signIn");
  const [isSigned, setIsSigned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);
  const [userAttr, setUserAttr] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [refreshDesignPending, setRefreshDesignPending] = useState(false);

  async function fetchUser() {
    try {
      const session = await Auth.currentSession(); //returns tokens
      setIdToken(session?.getIdToken());
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
      // console.log("fetch user > ", authenticatedUser);
      setIsAuthenticated(true);
      setIsAuthenticating(false);
      setIsSigned(true);
      setUserAttributes(authenticatedUser?.attributes);
      return authenticatedUser;
    } catch (error) {
      console.log("fetchUser >", error);
    }
  }

  useEffect(() => {
    //prevent logged in user from log out
    fetchUser().then((_) => {
      setIsAuthenticating(false);
    });
  }, []);

  const signOut = async () => {
    // console.log("SignOut clicked");

    setAuthState("signIn");

    setIsSigned(false);

    setUser(null);

    setUserAttr(null);

    await Auth.signOut()
      .then(() => {
        setIsAuthenticated(false);

        // setIsAuthenticating(true);
        setIsSigned(false);
        setUser(null);

        setUserAttr(null);
      })
      .catch((err) => console.log(err));
  };

  const forgotPassword = {
    requestCode: async (username) => {
      setIsLoading(true);
      const res = await Auth.forgotPassword(username)
        .then((_) => {
          // console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      return res;
    },
    setPassword: async (username, code, password) => {
      setIsLoading(true);
      const res = await Auth.forgotPasswordSubmit(username, code, password)
        .then((_) => {
          // console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      return res;
    },
  };

  async function signIn(username, password) {
    // setIsAuthenticating(true);

    try {
      const user = await Auth.signIn(username, password);
      setUser(user);
      setUserAttributes(user?.attributes);
      setIdToken(user?.signInUserSession?.idToken?.jwtToken);
      // console.log("signIn > ", user);
      // console.log("user", user);
      // console.log("userAtt", userAttributes);
      setIsSigned(true);
      setIsAuthenticated(true);
      setIsAuthenticating(false);

      return { statusCode: 200, user };
    } catch (error) {
      console.log("error signing in", error);
      return { statusCode: 400, error };
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        authState,
        setAuthState,
        isSigned,
        setIsSigned,
        isLoading,
        setIsLoading,
        user,
        setUser,
        userAttributes,
        setUserAttributes,
        userAttr,
        setUserAttr,
        tokens: {
          idToken,
        },
        signIn,
        signOut,
        forgotPassword,
        fetchUser,
        refreshDesignPending,
        setRefreshDesignPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
