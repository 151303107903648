import React from 'react'

function Closebtn() {
  return (
    <svg width="185" height="83" viewBox="0 0 185 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2172_313)">
<path d="M90.7635 0C93.8423 0 96.9166 0.00454496 99.9954 0C101.873 0 102.752 1.28168 102 3.00422C101.51 4.12682 100.957 5.21761 100.435 6.32658C99.6235 8.04912 100.258 9.08537 102.176 9.17627C107.554 9.4217 111.916 11.6805 114.922 16.1437C118.35 21.234 118.677 26.7243 115.834 32.1601C113.036 37.5141 108.456 40.3956 102.385 40.5274C96.513 40.6546 90.6365 40.5547 84.7646 40.5501C82.9872 40.5501 82.1302 39.3002 82.8194 37.6731C83.3816 36.3551 84.0164 35.0689 84.6104 33.7645C85.3087 32.2374 84.7918 31.4238 83.105 31.3875C75.968 31.2284 70.2185 27.1152 68.033 20.6114C64.7003 10.6761 72.1955 0.136349 82.6879 0.0227248C85.3813 -0.00908991 88.0747 0.0227248 90.7635 0.0227248V0ZM96.6536 31.3966C98.3313 31.3966 100.009 31.4193 101.691 31.3875C105.541 31.3193 108.488 28.4651 108.475 24.8427C108.461 21.0659 105.604 18.3571 101.619 18.348C99.5193 18.3434 97.4244 18.348 95.325 18.3434C93.339 18.3344 92.4956 17.0163 93.3118 15.1847C93.797 14.0985 94.3275 13.0349 94.8262 11.9532C95.6379 10.2034 94.9849 9.12627 93.076 9.11718C89.7206 9.10355 86.3607 9.04901 83.0053 9.15354C81.9261 9.18536 80.7699 9.48533 79.8041 9.97164C77.215 11.2669 75.8683 14.3621 76.5348 17.0663C77.2648 20.0024 79.8267 22.0567 82.969 22.1294C85.2044 22.1839 87.4444 22.1203 89.6843 22.1521C91.557 22.1748 92.3686 23.4838 91.6069 25.2063C91.0537 26.4516 90.4597 27.6788 89.8929 28.9196C89.1674 30.5103 89.6798 31.3602 91.4119 31.3875C93.1622 31.4147 94.9079 31.392 96.6581 31.392L96.6536 31.3966Z" fill="white"/>
<path d="M85.3171 18.575C86.8071 18.575 88.015 17.3643 88.015 15.8708C88.015 14.3772 86.8071 13.1665 85.3171 13.1665C83.827 13.1665 82.6191 14.3772 82.6191 15.8708C82.6191 17.3643 83.827 18.575 85.3171 18.575Z" fill="white"/>
<path d="M99.696 27.9744C101.186 27.9744 102.394 26.7637 102.394 25.2702C102.394 23.7767 101.186 22.5659 99.696 22.5659C98.2059 22.5659 96.998 23.7767 96.998 25.2702C96.998 26.7637 98.2059 27.9744 99.696 27.9744Z" fill="white"/>
<path d="M19.026 79.8C16.9175 81.8906 14.2786 82.9405 11.1091 82.9405C9.2636 82.9405 7.61765 82.6406 6.1712 82.0361C4.72475 81.4316 3.56397 80.6181 2.67978 79.5909C1.79559 78.5637 1.12904 77.4184 0.675613 76.1504C0.222181 74.8823 0 73.537 0 72.1054C0 70.6737 0.23125 69.3148 0.69375 68.0149C1.15625 66.715 1.82733 65.5424 2.71152 64.4835C3.59571 63.429 4.76556 62.5882 6.21654 61.9656C7.66752 61.3429 9.29988 61.0293 11.1091 61.0293C14.1199 61.0293 16.6863 62.0974 18.8174 64.229L16.5593 66.4014C15.0947 64.9516 13.2765 64.229 11.1091 64.229C8.60159 64.229 6.67451 65.0016 5.32782 66.5514C3.98113 68.1012 3.31912 69.942 3.34179 72.0735C3.35993 74.2051 4.02194 76.0231 5.32782 77.5184C6.6337 79.0182 8.56078 79.7682 11.1091 79.7682C12.1112 79.7682 13.1178 79.5682 14.1199 79.1637C15.1219 78.7592 15.988 78.2001 16.7089 77.473L19.026 79.7954V79.8Z" fill="white"/>
<path d="M39.3756 64.2471C41.2438 66.3877 42.1733 68.9874 42.1733 72.0462C42.1733 73.496 41.9466 74.8686 41.4977 76.1639C41.0443 77.4638 40.3959 78.6227 39.5389 79.6499C38.6864 80.6771 37.5574 81.4861 36.1517 82.0769C34.7461 82.6723 33.1636 82.9677 31.3952 82.9677C29.6269 82.9677 28.0444 82.6632 26.6388 82.0451C25.2331 81.4315 24.1041 80.6134 23.2516 79.5863C22.3992 78.5591 21.7462 77.4092 21.2928 76.1321C20.8394 74.855 20.6172 73.5006 20.6172 72.0735C20.6172 68.8556 21.6329 66.2059 23.6597 64.1243C25.6865 62.0427 28.2847 61.002 31.4587 61.002C34.8685 61.0201 37.5075 62.1018 39.3756 64.2471ZM24.4305 74.9004C24.7207 75.7958 25.156 76.6184 25.741 77.3729C26.3214 78.1274 27.1013 78.7318 28.0716 79.1818C29.0465 79.6363 30.1528 79.859 31.3998 79.859C33.8665 79.859 35.7255 79.1182 36.9679 77.641C38.2103 76.1639 38.8361 74.3187 38.8361 72.1053C38.8361 70.7372 38.5912 69.4692 38.097 68.3012C37.6073 67.1331 36.782 66.1423 35.6303 65.3288C34.4741 64.5152 33.0865 64.0971 31.4587 64.0789C29.0918 64.0789 27.2509 64.8243 25.9359 66.3105C24.621 67.8012 23.9635 69.7283 23.9635 72.1053C23.9816 73.0688 24.1403 74.0005 24.4305 74.8959V74.9004Z" fill="white"/>
<path d="M60.7187 76.4187V61.3931H64.0922V82.5135H61.5348L49.5824 67.606V82.5135H46.1816V61.3931H48.7707L60.7232 76.4187H60.7187Z" fill="white"/>
<path d="M81.9751 66.0698C81.4717 65.3472 80.7553 64.779 79.8213 64.3655C78.8872 63.9519 77.8715 63.7383 76.7651 63.7155C75.3187 63.7155 74.1443 64.0019 73.242 64.5745C72.3396 65.1472 71.8862 65.9289 71.8862 66.9152C71.8862 67.8423 72.3215 68.515 73.1966 68.9377C74.0672 69.3604 75.3368 69.6921 77.0054 69.933C78.2478 70.1148 79.3134 70.3239 80.1976 70.5648C81.0818 70.8057 81.9524 71.1647 82.8184 71.6374C83.6845 72.1101 84.3374 72.7554 84.7909 73.569C85.2443 74.3825 85.4665 75.3552 85.4665 76.4823C85.4665 77.9912 85.0312 79.2502 84.1561 80.2546C83.2809 81.2591 82.2199 81.9681 80.9639 82.3817C79.7079 82.7953 78.2977 82.9998 76.7334 82.9998C72.4167 82.9998 69.3969 81.4 67.6738 78.2003L70.204 76.5096C71.5688 78.823 73.7543 79.9774 76.7651 79.9774C77.6901 79.9774 78.529 79.8774 79.2953 79.6774C80.057 79.4774 80.7145 79.1002 81.2677 78.5457C81.8209 77.9912 82.0975 77.3049 82.0975 76.4778C82.0975 75.4097 81.6214 74.6234 80.6692 74.1098C79.717 73.5963 78.3975 73.2281 76.7107 73.01C75.7494 72.8872 74.9197 72.7464 74.2259 72.5873C73.5322 72.4282 72.8067 72.1964 72.0449 71.8919C71.2831 71.5919 70.6665 71.2283 70.1949 70.8057C69.7233 70.383 69.3334 69.833 69.0205 69.1604C68.7077 68.4877 68.5535 67.7151 68.5535 66.8515C68.5535 65.8062 68.7938 64.879 69.2744 64.0746C69.7551 63.2701 70.399 62.6338 71.2015 62.1748C72.0041 61.7112 72.8656 61.3658 73.7906 61.134C74.7156 60.9022 75.6769 60.7886 76.679 60.7886C80.4334 60.7886 83.0497 61.9748 84.5369 64.3473L81.9796 66.0698H81.9751Z" fill="white"/>
<path d="M97.2389 61.3931C98.9665 61.3931 100.517 61.6839 101.891 62.2657C103.265 62.8475 104.371 63.6383 105.201 64.6336C106.035 65.629 106.67 66.7288 107.115 67.9378C107.554 69.1468 107.786 70.4239 107.808 71.7692C107.849 73.1372 107.659 74.4507 107.237 75.7051C106.815 76.9641 106.19 78.1049 105.355 79.132C104.521 80.1592 103.406 80.9773 102 81.5909C100.594 82.2044 99.0118 82.5135 97.2434 82.5135H89.2676V61.3931H97.2434H97.2389ZM92.6048 79.2547H97.2389C99.6466 79.2547 101.465 78.5139 102.689 77.0368C103.913 75.5597 104.507 73.7917 104.467 71.7419C104.426 70.4966 104.167 69.3467 103.682 68.3014C103.202 67.2561 102.403 66.3789 101.288 65.6744C100.173 64.9699 98.8259 64.62 97.2389 64.62H92.6048V79.2547Z" fill="white"/>
<path d="M130.021 82.5179H126.076L119.665 75.2142H115.208V82.5179H111.834V61.3657C112.918 61.3657 114.555 61.3703 116.74 61.3794C118.926 61.3884 120.563 61.393 121.646 61.393C123.972 61.393 125.795 62.0702 127.11 63.4291C128.425 64.7881 129.083 66.4106 129.083 68.3013C129.083 69.9693 128.615 71.4146 127.681 72.6327C126.747 73.8507 125.31 74.6006 123.36 74.8824L129.654 81.9135L130.017 82.5179H130.021ZM115.212 64.4745V72.1373H121.624C122.948 72.1373 123.95 71.7737 124.634 71.051C125.319 70.3284 125.659 69.433 125.659 68.365C125.659 67.2969 125.319 66.4106 124.634 65.6334C123.95 64.8562 122.957 64.4699 121.655 64.4699H115.212V64.4745Z" fill="white"/>
<path d="M151.007 64.2471C152.875 66.3877 153.804 68.9874 153.804 72.0462C153.804 73.496 153.577 74.8686 153.129 76.1639C152.675 77.4638 152.027 78.6227 151.17 79.6499C150.317 80.6771 149.188 81.4861 147.783 82.0769C146.377 82.6723 144.794 82.9677 143.026 82.9677C141.258 82.9677 139.675 82.6632 138.27 82.0451C136.864 81.4315 135.735 80.6134 134.882 79.5863C134.03 78.5591 133.377 77.4092 132.924 76.1321C132.47 74.855 132.248 73.5006 132.248 72.0735C132.248 68.8556 133.264 66.2059 135.291 64.1243C137.317 62.0427 139.916 61.002 143.09 61.002C146.499 61.0201 149.138 62.1018 151.007 64.2471ZM136.061 74.9004C136.352 75.7958 136.787 76.6184 137.372 77.3729C137.952 78.1274 138.732 78.7318 139.702 79.1818C140.677 79.6363 141.784 79.859 143.031 79.859C145.497 79.859 147.356 79.1182 148.599 77.641C149.841 76.1639 150.467 74.3187 150.467 72.1053C150.467 70.7372 150.222 69.4692 149.728 68.3012C149.238 67.1331 148.413 66.1423 147.261 65.3288C146.105 64.5152 144.717 64.0971 143.09 64.0789C140.723 64.0789 138.882 64.8243 137.567 66.3105C136.252 67.8012 135.594 69.7283 135.594 72.1053C135.613 73.0688 135.771 74.0005 136.061 74.8959V74.9004Z" fill="white"/>
<path d="M157.809 82.5179V61.3975H161.15V82.5179H157.809Z" fill="white"/>
<path d="M174.426 61.3931C176.154 61.3931 177.705 61.6839 179.079 62.2657C180.453 62.8475 181.559 63.6383 182.389 64.6336C183.223 65.629 183.858 66.7288 184.302 67.9378C184.742 69.1468 184.973 70.4239 184.996 71.7692C185.037 73.1372 184.846 74.4507 184.425 75.7051C184.003 76.9641 183.377 78.1049 182.543 79.132C181.709 80.1592 180.593 80.9773 179.187 81.5909C177.782 82.2044 176.199 82.5135 174.431 82.5135H166.455V61.3931H174.431H174.426ZM169.792 79.2547H174.426C176.834 79.2547 178.652 78.5139 179.877 77.0368C181.101 75.5597 181.695 73.7917 181.654 71.7419C181.613 70.4966 181.355 69.3467 180.87 68.3014C180.389 67.2561 179.591 66.3789 178.476 65.6744C177.36 64.9699 176.013 64.62 174.426 64.62H169.792V79.2547Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2172_313">
<rect width="185" height="83" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default Closebtn
