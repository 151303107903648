import React from "react";

function Location() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.98438 9.625L6.5 8.48438L9.01562 9.625L9.25 9.39062L6.5 2.75L3.75 9.39062L3.98438 9.625ZM6.5 12.75C5.63542 12.75 4.82292 12.5858 4.0625 12.2575C3.30208 11.9296 2.64062 11.4844 2.07813 10.9219C1.51563 10.3594 1.07042 9.69792 0.7425 8.9375C0.414167 8.17708 0.25 7.36458 0.25 6.5C0.25 5.63542 0.414167 4.82292 0.7425 4.0625C1.07042 3.30208 1.51563 2.64062 2.07813 2.07813C2.64062 1.51563 3.30208 1.07021 4.0625 0.741875C4.82292 0.413958 5.63542 0.25 6.5 0.25C7.36458 0.25 8.17708 0.413958 8.9375 0.741875C9.69792 1.07021 10.3594 1.51563 10.9219 2.07813C11.4844 2.64062 11.9296 3.30208 12.2575 4.0625C12.5858 4.82292 12.75 5.63542 12.75 6.5C12.75 7.36458 12.5858 8.17708 12.2575 8.9375C11.9296 9.69792 11.4844 10.3594 10.9219 10.9219C10.3594 11.4844 9.69792 11.9296 8.9375 12.2575C8.17708 12.5858 7.36458 12.75 6.5 12.75ZM6.5 11.5C7.89583 11.5 9.07812 11.0156 10.0469 10.0469C11.0156 9.07812 11.5 7.89583 11.5 6.5C11.5 5.10417 11.0156 3.92187 10.0469 2.95312C9.07812 1.98437 7.89583 1.5 6.5 1.5C5.10417 1.5 3.92187 1.98437 2.95312 2.95312C1.98437 3.92187 1.5 5.10417 1.5 6.5C1.5 7.89583 1.98437 9.07812 2.95312 10.0469C3.92187 11.0156 5.10417 11.5 6.5 11.5Z"
        fill="#3D5AD0"
      />
    </svg>
  );
}

export default Location;
