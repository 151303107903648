import React, { useState, useEffect, useContext } from 'react';
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import "./style.css";
import { get, update } from '../../Utils/Functions/Methods';
import { AuthContext } from '../../Context/AuthContext';
import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

export default function Index() {
    const [isVisible, setIsVisible] = useState(false);
    const [bankDetails, setBankDetails] = useState(null);
    const [jobStatus, setJobStatus] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleSow = () => {
        setVisible(true)
    }
    const handleHide = () => {
        setVisible(false)
    }
    const { userAttributes } = useContext(AuthContext);
    const [paidAmount, setPaidAmount] = useState<number>(0);


    const params = useParams();
    const cad_user_key = params.key;
    console.log(cad_user_key)
    useEffect(() => {
        get(
            `/admin/user/${cad_user_key}/cad_user_payment`
        )
            .then((response) => {
                console.log(response[1][0].status);
                setBankDetails(response[1][0].bank_details);
                setJobStatus(response[1][0].status);
            })
            .catch((err) => console.log(err));
    }, [cad_user_key, refresh, userAttributes?.sub]);

    const CadPay = async () => {
        const tempData = {
            paid_amount: paidAmount
        };
        await update(`/admin/jobTable/${jobId}/update_cad_payment`, tempData)
            .then(([status, res]) => {
                if (status === 200) {
                    handleHide()
                    window.location.reload();
                    alert("Transaction Recorded Successfully");
                } else {
                    alert("Something went wrong");
                    console.log("result fail", res);
                }
            })
            .catch((err) => console.log(err));
    };



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setPaidAmount(isNaN(value) ? 0 : value);
    };

    return (
        <>
            <div>
                <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />

            </div>
            <div>
                <div className="d-flex justify-content-center">
                    {bankDetails && (Object.keys(bankDetails).length !== 0 || null ? (
                        <div className="payment-track-card d-flex flex-column gap-2  align-items-center py-2 ">
                            <h5 className="fw-bold" style={{ color: "#0C5AE5" }}>Account Details</h5>
                            <div>Account holder Name:{bankDetails?.account_holder_name} </div>
                            <div>Bank Name: {bankDetails?.bank_name} </div>
                            <div>Account Number: {bankDetails?.account_number}</div>
                            <div>Ifsc code: {bankDetails?.ifsc_code}</div>
                            <div>Mobile: {bankDetails?.phone_number}</div>
                            <div>Upi id: {bankDetails?.upi_id}</div>
                        </div>
                    ) : <>
                        <div className="mt-5 text-danger">You haven't added a bank account yet.
                        </div>

                    </>
                    )}
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Job Id</th>
                            <th>Site Key</th>
                            <th>Job Status</th>
                            <th>    </th>



                        </tr>
                    </thead>
                    <tbody>
                        {jobStatus?.map((data, index) => {

                            return (
                                <tr
                                    style={{ cursor: "pointer" }}


                                >
                                    <td>{data?.job_id}</td>
                                    <td>{data?.site_key}</td>
                                    <td>{data?.job_status}</td>
                                    <td>
                                        {data?.job_status === 'completed' && data?.paid_status === 0 ? (
                                            <div onClick={() => { handleSow(); setJobId(data?.job_id); }} className="pay-btn">Pay</div>
                                        ) : data?.paid_status === 1 ? (
                                            <div className="salary-paid text-success fw-bold ">Salary Paid</div>
                                        ) : null}

                                    </td>


                                </tr>



                            );

                        })}
                    </tbody>
                </table>
                <Modal show={visible} onHide={handleHide}>
                    <ModalHeader closeButton>
                        <div className=" text-muted fw-bold">  {jobId}</div>

                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <label className="fw-bold">Amount:</label>
                            <input className="form-control mt-3" type="text" placeholder='Enter the Amount'
                                onChange={handleInputChange}
                            />
                            <div onClick={() => CadPay()} className="pay-btn mt-5  ">Pay Now</div>
                        </div>
                    </ModalBody>
                </Modal>
            </div >

            <Sidebar
                className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
                    }`}
                sidebarOnClickClose={() => setIsVisible(false)}
            />
        </>
    )
}
