import React, { useEffect, useState } from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Button from "react-bootstrap/Button";
// import data from "../MemberApproval/memberapproval.json";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { get } from "../../Utils/Functions/Methods";
import DesignAccepted from "./DesignAccepted";
import DesignRejected from "./DesignRejected";
import DesignPending from "./DesignPending";
import { useNavigate, useParams } from "react-router";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const params = useParams();
  const designStatus = params.designStatus;
  const [isVisible, setIsVisible] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [result, setResult] = useState("");
  const [data, setData] = useState([]);
  usePreventScroll(isVisible);
  const [refreshData, setRefreshData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    get(`/cad/applications`)
      .then((response) => {
        setData(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, [refreshData]);

  const switchDetails = () => {
    switch (designStatus) {
      case "accepted":
        return <DesignAccepted />;
      case "rejected":
        return <DesignRejected />;
      case "pending":
        return <DesignPending />;
    }
  };
  const unSelectedClass = "";
  const selectedClass = "selected-item";

  return (
    <div>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="memberApproval-container">
        <div className="membersApproval-statusBox">
          <div
            className={`membersApproval-statusCard ${
              designStatus == "pending" ? selectedClass : unSelectedClass
            }`}
            onClick={() => navigate("/designRequests/pending")}
          >
            Pending
          </div>
          <div
            className={`membersApproval-statusCard ${
              designStatus == "accepted" ? selectedClass : unSelectedClass
            }`}
            onClick={() => navigate("/designRequests/accepted/ongoing")}
          >
            Accepted
          </div>
          <div
            className={`membersApproval-statusCard ${
              designStatus == "rejected" ? selectedClass : unSelectedClass
            }`}
            onClick={() => navigate("/designRequests/rejected")}
          >
            Rejected
          </div>
        </div>

        {switchDetails()}
      </div>

      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </div>
  );
}

export default Index;
