import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function Index({ individualData, customerData }) {
  return (
    <div>
      <div className="mt-1">
        {/* Home Type */}
        <Row>
          <Col sm={6} className="mar-left">
            <Form.Label className="req-form fw-bold mx-5">Name:</Form.Label>
            <div
              className="w-90 mx-5  p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.name}
            </div>
          </Col>
          {/* 1500sqft */}
          <Col sm={6}>
            <Form.Label className="req-form fw-bold">Email:</Form.Label>
            <div
              className="w-90  p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.email}
            </div>
          </Col>

          {/* bhk_type */}

          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold mx-5">Number:</Form.Label>
            <div
              className="w-90 mx-5 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.number}
            </div>
          </Col>
          {/*  type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Type:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.type}
            </div>
          </Col>
        </Row>

        {/* No. Floors */}

        <Row>
          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold mx-5">
              Project Key :
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.project_key}
            </div>
          </Col>
          {/* Floor type */}
          {/* <Col sm={6} className=" mt-3">
                        <Form.Label className="req-form fw-bold">C-Site:</Form.Label>
                        <div
                          className="w-90 mt-1 p-1 shadow-sm rounded"
                          style={{ border: "solid 1px #A89F9F" }}
                        >
                          {individualData?.c_site_plancol}
                        </div>
                      </Col> */}

          {/* No. of windows */}

          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold ">Area:</Form.Label>
            <div
              className="w-100 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.area + " " + individualData?.area_type}
            </div>
          </Col>
          {/* Window type */}
          {/* <Col sm={6} className=" mt-3">
                        <Form.Label className="req-form fw-bold">Area Type:</Form.Label>
                        <div
                          className="w-90 mt-1 p-1 shadow-sm rounded"
                          style={{ border: "solid 1px #A89F9F" }}
                        >
                          {individualData?.area_type}
                        </div>
                      </Col> */}
        </Row>

        {/* No. of Bathroom */}
        <Row>
          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold mx-5  ">
              Number of Floors:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.number_of_floors}
            </div>
          </Col>
          {/* No. of Bedroom */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Floor Type:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.floor_type}
            </div>
          </Col>

          {/* Loft Cover */}

          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold mx-5">
              Number of Windows:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.number_of_windows}
            </div>
          </Col>
          {/* Lift */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Window Type:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.window_type}
            </div>
          </Col>
        </Row>

        {/* Modular Kitchen */}
        <Row>
          <Col sm={6} className="mar-left mt-3">
            <Form.Label className="req-form fw-bold mx-5 ">
              Number of Bathroom:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.number_of_bathroom}
            </div>
          </Col>
          {/* Kitchen Type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">
              Number of Bedroom:
            </Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.number_of_bedroom}
            </div>
          </Col>

          {/* Car Parking */}

          <Col sm={6} className="mar-left mt-3 ">
            <Form.Label className="req-form fw-bold mx-5">
              Loft Cover:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 mb-3 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.loft_cover}
            </div>
          </Col>
          {/* Parking Floors */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Lift:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.lift}
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="mar-left mt-3 ">
            <Form.Label className="req-form fw-bold mx-5">
              Modular Kitchen:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 mb-3 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.is_modular_kitchen}
            </div>
          </Col>
          {/* Parking Floors */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Kitchen Type:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.kitchen_type}
            </div>
          </Col>

          <Col sm={6} className="mar-left mt-3 ">
            <Form.Label className="req-form fw-bold mx-5">
              Car Parking:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 mb-3 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.is_car_parking}
            </div>
          </Col>
          {/* Parking Floors */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">BHK type:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.bhk_type}
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col sm={6} className="mar-left mt-3 ">
            <Form.Label className="req-form fw-bold mx-5">
              Created at:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 mb-3 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.created_at}
            </div>
          </Col>
    
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Created by:</Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {customerData?.firstname + " " + customerData?.lastname}
            </div>
          </Col>

    
        </Row> */}

        <Row>
          <Col sm={6} className="mar-left mt-3 ">
            <Form.Label className="req-form fw-bold mx-5">
              Parking Floor:
            </Form.Label>
            <div
              className="w-90 mx-5 mt-1 p-1 mb-3 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.parking_floor}
            </div>
          </Col>
          {/* Parking Floors */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">
              Request Status:
            </Form.Label>
            <div
              className="w-90 mt-1 p-1 shadow-sm rounded"
              style={{ border: "solid 1px #A89F9F" }}
            >
              {individualData?.request_status}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
