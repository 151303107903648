import React, { useEffect, useState } from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Button from "react-bootstrap/Button";
// import data from "../MemberApproval/memberapproval.json";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { get } from "../../Utils/Functions/Methods";
import MembersAccepted from "../MembersAccepted";
import MembersRejected from "../MembersRejected";
import MembersPending from "../MembersPending";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [result, setResult] = useState("");
  const [data, setData] = useState([]);
  const [changeDetails, setChangeDetails] = useState("members-pending");
  const [refreshData, setRefreshData] = useState(false);
  usePreventScroll(isVisible);
  useEffect(() => {
    get(`/cad/applications`)
      .then((response) => {
        setData(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, [refreshData]);
  // const pendingFiltered = data.filter((data) => {
  //   return data.application_status == "pending";
  // });

  // const accepted = function (data) {
  //   setPopupShow(true);
  //   console.log(data);
  //   setPopupName(data.first_name + " " + data.last_name);
  //   setResult("Accepted");
  // };
  // const decline = function (data) {
  //   setPopupShow(true);
  //   setPopupName(data.first_name + " " + data.last_name);
  //   setResult("Declined");
  // };

  const switchDetails = () => {
    switch (changeDetails) {
      case "members-accepted":
        return <MembersAccepted data={data} />;
      case "members-rejected":
        return <MembersRejected data={data} />;
      case "members-pending":
        return (
          <MembersPending
            data={data}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          />
        );
    }
  };
  const unSelectedClass = "";
  const selectedClass = "selected-item";

  return (
    <div>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="memberApproval-container">
        <div className="membersApproval-statusBox">
          <div
            className={`membersApproval-statusCard ${
              changeDetails == "members-pending"
                ? selectedClass
                : unSelectedClass
            }`}
            onClick={() => setChangeDetails("members-pending")}
          >
            Pending
          </div>
          <div
            className={`membersApproval-statusCard ${
              changeDetails == "members-accepted"
                ? selectedClass
                : unSelectedClass
            }`}
            onClick={() => setChangeDetails("members-accepted")}
          >
            Accepted
          </div>
          <div
            className={`membersApproval-statusCard ${
              changeDetails == "members-rejected"
                ? selectedClass
                : unSelectedClass
            }`}
            onClick={() => setChangeDetails("members-rejected")}
          >
            Rejected
          </div>
        </div>

        {switchDetails()}
      </div>

      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </div>
  );
}

export default Index;
