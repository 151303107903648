import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../../Context/StateContext";
import { get } from "../../../../Utils/Functions/Methods";
import FolderIcon from "../../../../Assets/Icons/folderIcon.svg";

function Index({ setProjectFiles }) {
  const { individualJobDetails } = useContext(StateContext);
  const [sectionDetails, setSectionDetails] = useState(null);

  const getFilesFunction = async (data) => {
    await get(
      `/admin/jobProject/${individualJobDetails.job_id}/jobProject/${data.project_key}/section/${data.section_key}/files`
    )
      .then((response) => {
        setProjectFiles(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  };

  useEffect(() => {
    get(
      `/admin/jobProject/${individualJobDetails?.job_id}/jobProject/${individualJobDetails?.project_folder_key}?design_template=2d`
    )
      .then((response) => {
        setSectionDetails(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, []);

  return (
    <div className="d-flex align-items-center flex-wrap folder-container gap-3">
      {sectionDetails?.map((data) => {
        return (
          <div
            className="folder-box"
            onClick={() => {
              getFilesFunction(data);
            }}
          >
            <img src={FolderIcon}></img>
            <div>{data.section_name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Index;
