import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { Button } from "react-bootstrap";

import Phone from "../../Assets/Icons/phone";
import Mail from "../../Assets/Icons/mail2";
import Location from "../../Assets/Icons/location";
import { get } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import Pagination from "../../Components/Pagination";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  const { userAttributes } = useContext(AuthContext);
  const [preRegistrationList, setPreRegistrationList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 50;

  useEffect(() => {
    get(`/admin/user/${userAttributes?.sub}/all_preRegistration_user`)
      .then((response) => {
        setPreRegistrationList(response[1]);

      })
      .catch((err) => console.log(err));
  }, []);

  usePreventScroll(isVisible);

  return (
    <>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>Registration Key</th>
              <th>Full Name</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {preRegistrationList?.map((data, index) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  key={data?.preRegistration_key}
                >
                  <td>{data?.preRegistration_key}</td>
                  <td>{data?.full_name}</td>
                  <td>{data?.company_name}</td>
                  <td>{data?.email}</td>
                  <td>{data?.phone}</td>

                  <td className={`d-flex${data?.categories && data.categories.length > 0 ? '' : ' align-items-start'}`} key={index}>
                    {data?.categories && data.categories.length > 0 ? (
                      data.categories.map((category, index) => (
                        <span key={index}>
                          {category.category_name !== null && category.category_name !== "" ? category.category_name : "Not mentioned"}
                          {index !== data.categories.length - 1 && ","}
                        </span>
                      ))
                    ) : (
                      <span className="empty-data">No data</span>
                    )}
                  </td>

                </tr>
              );
            })}
          </tbody>
        </table>
        {preRegistrationList && (
          <div className="p-4">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={preRegistrationList.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

      <Sidebar
        className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
          }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
