import { useEffect } from "react";

function usePreventScroll(isVisible) {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("preventScroll");
    } else {
      document.body.classList.remove("preventScroll");
    }
  }, [isVisible]);
}

export default usePreventScroll;
