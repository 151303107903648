import React, { useEffect, useState } from "react";
import "../MemberApproval/style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Button from "react-bootstrap/Button";
// import data from "../MemberApproval/memberapproval.json";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { get } from "../../Utils/Functions/Methods";
import Phone from "../../Assets/Icons/phone";
import Country from "../../Assets/Icons/country";
import Mail from "../../Assets/Icons/mail2";
import Experience from "../../Assets/Icons/experience";
import Company from "../../Assets/Icons/company";
import Location from "../../Assets/Icons/location";
import { ModalBody } from "react-bootstrap";

function Index(props) {
  const [popupShow, setPopupShow] = useState(false);
  const [popupMenu, setPopupMenu] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [result, setResult] = useState("");
  const [download, setDownload] = useState<any>();
  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(false);
  const [individualData, setIndividualData] = useState(null);

  const accepted = function (data) {
    setPopupShow(true);
    setPopupName(data.first_name + " " + data.last_name);
    setResult("Accepted");
  };
  const decline = function (data) {
    setPopupShow(true);
    setPopupName(data.first_name + " " + data.last_name);
    setResult("Declined");
  };
  const filteredData = props.data.filter((data) => {
    return data.application_status == "rejected";
  });

  const handleFileDownload = async (data) => {
    const { file_name, path, extension } = data;

    await get(
      `/getUrl?file_name=${file_name}&path=${path}&extension=${extension}`
    )
      .then((response) => {
        setDownload(response[1]);
      })

      .catch((error) => {
        console.log("Files fetch error", error);
      });
  };
  const handleAccept = (data) => {
    setAccept(true);
    // setFiles(data);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setPopupMenu(true);
  };

  return (
    <>
      {filteredData?.map((data) => {
        return (
          <div className="memberApproval-card">
            <div className="member-name-details">
              <div className="member-profile-photo"></div>
              <div className="member-profile-name">
                {data.first_name + " " + data.last_name}
              </div>
            </div>
            <div className="member-contact-details-container">
              <div className="member-contact-detail-box">
                <div className="member-contact-icon">
                  <Phone />
                </div>
                <div className="member-contact-detail">{data.phone_number}</div>
              </div>
              <div className="member-contact-detail-box">
                <div className="member-contact-icon">
                  <Location />
                </div>
                <div className="member-contact-detail">{data.country}</div>
              </div>
              <div className="member-contact-detail-box">
                <div className="member-contact-icon">
                  <Mail />
                </div>
                <div className="member-contact-detail">{data.email}</div>
              </div>
            </div>

            <div className="memberApproval-response">
              <Button
                onClick={() => {
                  handleShow();
                  setIndividualData(data);
                }}
              >
                View
              </Button>
              {/* <Button
                variant="primary"
                onClick={() => {
                  handleFileDownload({
                    extension: data?.file_type,
                    file_name: data?.file_name,
                    path: data?.location,
                  });
                  console.log("link", download?.downloadUrl);
                  handleShow(data);
                }}
              >
                Download Resume
              </Button>
              <Button
                variant="outline-success"
                onClick={() => {
                  accepted(data);
                }}
                id="Accepted"
              >
                Accept
              </Button>{" "}
              <Button variant="outline-danger" onClick={() => decline(data)}>
                Decline
              </Button>{" "} */}
            </div>
          </div>
        );
      })}
      {/* <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={popupShow}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {popupName} Has Been {result}
          </Modal.Title>
          <CloseButton onClick={() => setPopupShow(false)} />
        </Modal.Header>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button onClick={() => setPopupShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={popupMenu}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Details
          </Modal.Title>
          <CloseButton onClick={() => setPopupMenu(false)} />
        </Modal.Header>
        <ModalBody>
          <div className=" profile-content">
            <div className="d-flex profile">
              <div>
                <div className="member-profile-photo"></div>
              </div>
              <div className="d-flex all-proifle">
                <div className="profile-1">
                  <div className="d-flex name">
                    <div className="name-1">{individualData?.first_name}</div>
                    <div className="name-2">{individualData?.last_name}</div>
                  </div>
                  <div className="d-flex number-content">
                    <div>
                      <Phone />
                    </div>
                    <div className="number-main">
                      {individualData?.phone_number}
                    </div>
                  </div>
                  <div className="d-flex number-content">
                    <div>
                      <Mail />
                    </div>
                    <div className="mail-main">{individualData?.email}</div>
                  </div>
                  <div className="d-flex address-1">
                    <div>
                      <Location />
                    </div>
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="address1-main">
                      {individualData?.address1}
                    </div>
                  </div>
                  <div className="d-flex address-2">
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="address2-main">
                      {individualData?.address2}
                    </div>
                  </div>
                  <div className="d-flex zipcode">
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="zipcode-main">
                      {individualData?.zipcode}
                    </div>
                  </div>
                  <div className="d-flex country">
                    <div>
                      <Country />
                    </div>
                    <div className="country-main">
                      {individualData?.country}
                    </div>
                  </div>
                  <div className="d-flex companyname">
                    <div>
                      <Company />
                    </div>
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="companyname-main">
                      {individualData?.company_name}
                    </div>
                  </div>

                  <div className="d-flex experience">
                    <div>
                      <Experience />
                    </div>
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="experience-main">
                      {individualData?.experience}
                    </div>
                  </div>

                  <div className="d-flex applicationstatus">
                    {/* <img src={mail} className="phone" alt="" /> */}
                    <div className="applicationstatus-main">
                      {individualData?.application_status}
                    </div>
                  </div>
                </div>
                {/* <div className="download-button">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleFileDownload({
                        extension: individualData?.file_type,
                        file_name: individualData?.file_name,
                        path: individualData?.location,
                      });
                      handleAccept(individualData);
                    }}
                  > */}
                    {/* <a href={data?.resume_unique_key} download={data?.resume_name}>Download Resume</a> */}
                    {/* Download Resume
                  </Button>
                  <div className="workfile-button">
                    <Button> */}
                      {/* <a href={data?.resume_unique_key} download={data?.resume_name}>Download Resume</a> */}
                      {/* Download workfile
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </ModalBody>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button onClick={() => setPopupMenu(false)}>Close</Button>
          {/* <Button
                variant="outline-success"
                onClick={() => {
                  accepted(individualData);
                }}
                id="Accepted"
              >
                Accept
              </Button>{" "}
              <Button variant="outline-danger" onClick={() => decline(individualData)} >
                Decline
              </Button>{" "} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
