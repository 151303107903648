import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { get } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import { Line } from "react-chartjs-2";
import usePreventScroll from "../../CustomHooks/PreventScroll";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip
);

// import { useHistory } from "react-router";

export default function Index() {
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const { userAttributes } = useContext(AuthContext);
  const navigate = useNavigate();
  const [preRegistrationCount, setPreRegistrationCount] = useState(null);
  const [userDetailsByDate, setUserDetailsByDate] = useState(null);

  useEffect(() => {
    get(`/admin/user/${userAttributes?.sub}/user_count`)
      .then((response) => {
        setUserDetails(response[1][0]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
    get(`/admin/user/${userAttributes?.sub}/preRegistration/count`)
      .then((response) => {
        setPreRegistrationCount(response[1][0]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
    get(`/admin/user/${userAttributes?.sub}/count_by_date`)
      .then((response) => {
        // setUserDetailsByDate(response[1]);
        handleDateCorrections(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, []);

  const handleDateCorrections = (data: { Date: string }[]) => {
    data.sort(
      (a: { Date: string }, b: { Date: string }) =>
        +new Date(a.Date) - +new Date(b.Date)
    );
    setUserDetailsByDate(data.slice(-30));
  };
  const [isVisible, setIsVisible] = useState(false);

  usePreventScroll(isVisible);

  return (
    <div>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="d-flex dashboard-cards">
        <div className="dash-card1">
          <div className="status-1">Users</div>
          <div className="number1">
            {userDetails?.no_of_users < 10
              ? "0" + userDetails?.no_of_users
              : userDetails?.no_of_users}
          </div>
          <div
            className="view-status"
            onClick={() => {
              navigate("/users");
            }}
          >
            View
          </div>
        </div>
        <div className="dash-card2">
          <div className="status-1">Cad Designers </div>
          <div className="number1">
            {userDetails?.no_of_cadUsers < 10
              ? "0" + userDetails?.no_of_cadUsers
              : userDetails?.no_of_cadUsers}
          </div>
          <div
            className="view-status"
            onClick={() => {
              navigate("/memberApproval");
            }}
          >
            View
          </div>
        </div>

        <div className="dash-card3">
          <div className="status-1">Pre-Registered Users</div>
          <div className="number3">
            {preRegistrationCount?.no_of_preRegistered_users < 10
              ? "0" + preRegistrationCount?.no_of_preRegistered_users
              : preRegistrationCount?.no_of_preRegistered_users}
          </div>
          <div
            className="view-status"
            onClick={() => {
              navigate("/preRegistration");
            }}
          >
            View
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 align-items-center justify-content-center w-full mt-5 px-3">
        <div style={{ fontWeight: "bold" }}>User Joining History </div>
        <div style={{ width: "1000px" }}>
          <Line
            datasetIdKey="id"
            data={{
              labels: userDetailsByDate?.map((data) => data?.Date),
              datasets: [
                {
                  data: userDetailsByDate?.map((data) => data?.count),
                  backgroundColor: "#ff8180",
                  borderColor: "black",
                  pointBorderColor: "red",
                  tension: 0.1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    font: {
                      size: 14,
                    },
                  },
                  title: {
                    display: true,
                    text: "User Details By Date",
                    font: {
                      size: 18,
                      weight: "bold",
                    },
                  },
                },
              },
            }}
          />
        </div>
      </div>

      <br />

      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </div>
  );
}
