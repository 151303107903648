import React, { useEffect, useState } from 'react'
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import "./style.css";


export default function Index() {

    const [isVisible, setIsVisible] = useState(false);
    const [notes, setNotes] = useState("");

    const [notesSaved, setNotesSaved] = useState(false);


    // useEffect(() => {
    //     const storeNotes = localStorage.getItem('notesSaved');
    //     if (storeNotes) {
    //         setNotesSaved(JSON.parse(storeNotes));
    //     }
    // }, []);

    // useEffect(() => {
    //     localStorage.setItem('notesSaved', notesSaved.toString());
    // }, [notesSaved]);


    const handleNotesChange = (event) => {
        setNotes(event.target.value);
        setNotesSaved(false);
    };

    const handleSaveNotes = () => {

        console.log("Notes saved:", notes);
        setNotesSaved(true);

    };

    const handleRetrieveNotes = () => {

        console.log("Notes retrieved:", notes);
    };
    return (
        <>

            <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
            <div className="member-response">
                <div>Customer Name :John</div>
                <div>Customer Mail :John@gmail.com</div>
                <div>Contacted on :13/03</div>
                <label>Contacted By:</label>
                <select className="form-control "  >
                    <option value="0">--Please choose an option--</option>
                    <option value="Mail">Mail </option>
                    <option value="Inperson">Inperson</option>

                </select>







            </div>

            <div className="py-5 d-flex flex-column gap-4 customer-notes ">

                <label className="fw-bold">Notes</label>
                <textarea className="form-control " value={notes} onChange={handleNotesChange} />

                <div className="d-flex flex-row gap-3 align-items-center justify-content-center">
                    <button className="reassign-job fw-bold " onClick={handleSaveNotes}>Save Notes</button>
                    <button className="reassign-job fw-bold " onClick={handleRetrieveNotes}>Retrieve Notes</button></div>

                {notesSaved && (
                    <div>
                        <h2>Notes Taken:</h2>
                        <p>{notes}</p>
                    </div>
                )}
            </div>
            <Sidebar
                className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
                    }`}
                sidebarOnClickClose={() => setIsVisible(false)}
            />
        </>
    )
}
