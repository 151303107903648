import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Navigate, useNavigate } from "react-router";
import { initSchema } from "@aws-amplify/datastore";
import { AuthContext } from "../../../Context/AuthContext";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Logo from '../../../Assets/Logo/Consdroid-logo.png'

export default function Index() {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const initialValues = { email: "", password: "" };
  const [values, setValues] = useState(initialValues);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loginCred, setLoginCred] = useState(initialValues);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const tempValues = { ...values, [name]: value };

    setValues(tempValues);
    if (e.target.name == "email") {
      if (!tempValues?.email) {
        setEmailError("Email is Required");
      } else if (!emailRegex.test(tempValues?.email)) {
        setEmailError("Please Enter a Valid Email");
      } else {
        setEmailError("");
      }
    }
    if (e.target.name == "password") {
      if (!tempValues?.password) {
        setPasswordError("Password is Required");
      } else if (tempValues?.password.length < 5) {
        setPasswordError("Minimum 5 Characters Required");
      } else {
        setPasswordError("");
      }
    }
  };

  useEffect(() => {
    setIsDisabled(
      !(
        emailRegex.test(values?.email) &&
        values?.password &&
        values?.password.length > 4
      )
    );
  }, [values]);

  const handleSubmit = async () => {
    if (values.password !== "") {
      await signIn(values.email, values.password).then((res) => {
        if (res?.statusCode === 200) {
          navigate("/dashboard");
        } else if (res.statusCode === 400) {
          setError(res?.error?.message || "Something Went Wrong");
          console.log("login fail", res);
        } else if (res.statusCode === 201) {
          navigate("/");
        }
      });
    } else {
      setError("Password cannot be empty");
    }
    console.log(error);
  };

  // !Have to Check how to call a function inside the useEffect for the Below COde
  // const handleSubmit=(e)=>{
  //   e.preventDefault();
  //   setErrors(validate(formValues));

  // }
  // const validate=(values)=>{

  //    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  //   const emailRegex= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  //   if(!values.email){
  //     errors.email="Email is Required"
  //   }else if(!emailRegex.test(values.email)){
  //     errors.email="Please Enter a Valid Email"
  //   }else {
  //     errors.email=""
  //   }
  //   if(!values.password){
  //     errors.password="Password is Required"
  //   }else if(values.password.length<8){
  //     errors.password="Minimum 5 Characters Required"
  //   }else{
  //     errors.password=""
  //   }
  //   return errors;
  // }
  // console.log(errors)

  return (
    <div className="loginpage-container">
      <div className="login-box">
        <img src={Logo} height='30px'></img>
        <div className="login-box-title">Login</div>
        <div className="login-box-input">
          <input
            name="email"
            placeholder="Email"
            className="login-box-input-field"
            onChange={handleChange}
            value={values?.email}
          ></input>
          <p className="login-auth-error">{emailError}</p>
        </div>
        <div className="login-box-input login-box-input-eye">
          <input
            name="password"
            placeholder="Password"
            className="login-box-input-field"
            onChange={handleChange}
            value={values?.password}
            type={showPassword ? "text" : "password"}
          />

          {showPassword ? (
            <Eye className="login-eye" onClick={() => { setShowPassword(!showPassword) }} onMouseDown={(e) => e.preventDefault}></Eye>
          ) : (
            <EyeSlash className="login-eye" onClick={() => { setShowPassword(!showPassword) }} onMouseDown={(e) => e.preventDefault}></EyeSlash>
          )}

          <p className="login-auth-error">{passwordError}</p>
        </div>
        <button
          className="login-box-btn"
          disabled={isDisabled}
          onClick={handleSubmit}
        >
          <div>Login</div>
        </button>
        <div
          className="login-box-forgotpass"
          onClick={() => {
            navigate("/forgotpassword");
          }}
        >
          Forgot Password?
        </div>
        <div
          className="login-box-forgotpass"
          onClick={() => {
            navigate("/signup");
          }}
        >
          SignUp
        </div>
      </div>
    </div>
  );
}
