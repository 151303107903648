import React, { useState } from "react";
import convertToIndianCurrency from "../../Utils/General/indianNumberFormat";
import { get } from "../../Utils/Functions/Methods";
import { Modal } from "react-bootstrap";
import "./style.css";
function Index({ sitePlanDetails }) {
  const [download, setDownload] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handleFileDownload = async (data) => {
    const { file_name, path, extension } = data;

    await get(
      `/getUrl?file_name=${file_name}&path=${path}&extension=${extension}`
    )
      .then((response) => {
        setDownload(response[1]);
      })

      .catch((error) => {
        console.log("Files fetch error", error);
      });
  };

  return (
    <>
      <div className="d-flex flex-column gap-5 p-3">
        <div className="d-flex gap-2 flex-column">
          <div>
            <p style={{ color: "#3d5ad0", fontSize: "20px" }}>USER DETAILS</p>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="detail_box">
              <p className="detail_key">Name</p>
              <p className="detail_value">
                {sitePlanDetails?.first_name + " " + sitePlanDetails?.last_name}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Email</p>
              <p className="detail_value">{sitePlanDetails?.email}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Address</p>
              <p className="detail_value">{sitePlanDetails?.address}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Phone</p>
              <p className="detail_value">{sitePlanDetails?.phone}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">City</p>
              <p className="detail_value">
                {sitePlanDetails?.city?.split("-")[0]}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">State</p>
              <p className="detail_value">{sitePlanDetails?.state}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Country</p>
              <p className="detail_value">{sitePlanDetails?.country}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Zipcode</p>
              <p className="detail_value">{sitePlanDetails?.zipcode}</p>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2 flex-column">
          <div>
            <p style={{ color: "#3d5ad0", fontSize: "20px" }}>SITE DETAILS</p>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="detail_box">
              <p className="detail_key">Site Name</p>
              <p className="detail_value">{sitePlanDetails?.site_name}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Address</p>
              <p className="detail_value">{sitePlanDetails?.site_address}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">City</p>
              <p className="detail_value">
                {sitePlanDetails?.site_city?.split("-")[0]}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">State</p>
              <p className="detail_value">{sitePlanDetails?.site_state}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Country</p>
              <p className="detail_value">{sitePlanDetails?.site_country}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Zipcode</p>
              <p className="detail_value">{sitePlanDetails?.site_zipcode}</p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Area</p>
              <p className="detail_value">
                {sitePlanDetails?.site_area + " " + "Sq.Ft"}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Length</p>
              <p className="detail_value">
                {sitePlanDetails?.site_length + " " + "Sq.Ft"}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Breadth</p>
              <p className="detail_value">
                {sitePlanDetails?.site_breadth + " " + "Sq.Ft"}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Budget</p>
              <p className="detail_value">
                {convertToIndianCurrency(sitePlanDetails?.budget_min) +
                  " - " +
                  convertToIndianCurrency(sitePlanDetails?.budget_max)}
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2 flex-column">
          <div>
            <p style={{ color: "#3d5ad0", fontSize: "20px" }}>
              SITE AREA REQUIREMENTS
            </p>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="detail_box">
              <p className="detail_key">Outer Area</p>
              <p className="detail_value">
                {sitePlanDetails?.outer_gap_area + " " + "Sq.Ft"}
              </p>
            </div>
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <div className="detail_card">
                <p className="detail_card_key">FRONT AREA</p>
                <p className="detail_card_value">
                  {sitePlanDetails?.front_area + " " + "Sq.Ft"}
                </p>
              </div>

              <div className="detail_card">
                <p className="detail_card_key">BACK AREA</p>
                <p className="detail_card_value">
                  {sitePlanDetails?.back_area + " " + "Sq.Ft"}
                </p>
              </div>
            </div>

            <div className="detail_box">
              <p className="detail_key">Parking</p>
              <p className="detail_value">
                {sitePlanDetails?.is_parking == "1" ? "Yes" : "No"}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Garden</p>
              <p className="detail_value">
                {sitePlanDetails?.is_garden == "1" ? "Yes" : "No"}
              </p>
            </div>

            <div className="detail_box">
              <p className="detail_key">Floors</p>
              <p className="detail_value">{sitePlanDetails?.floors}</p>
            </div>
          </div>
        </div>

        {sitePlanDetails?.floorDetails?.map((data) => {
          return (
            <div className="d-flex gap-2 flex-column">
              <div>
                <p style={{ color: "#3d5ad0", fontSize: "24px" }}>
                  {data?.floor_number == 0
                    ? "Ground Floor Details"
                    : `Floor -${data.floor_number} Details`}
                </p>
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="detail_box">
                  <p className="detail_value">{data?.floor_description}</p>
                </div>

                <div className="detail_box">
                  <p className="detail_key">Hall Type</p>
                  <p className="detail_value">{data.floor_halltype}</p>
                </div>

                <div className="detail_box">
                  <p className="detail_key">Balcony</p>
                  <p className="detail_value">
                    {data.is_floor_balcony == "1" ? "Yes" : "No"}
                  </p>
                </div>

                <div className="detail_box">
                  <p className="detail_key">BedRooms</p>
                  <p className="detail_value">{data.floor_bedroom_count}</p>
                </div>

                <div className="d-flex flex-wrap gap-3 align-items-center">
                  {data?.bedRoomDetails.map((data) => {
                    return (
                      <div className="detail_card">
                        <p className="detail_card_value">
                          {data.bedroom_type + " " + "Room"}
                        </p>
                        <p
                          className="detail_card_value"
                          style={{ fontSize: "12px" }}
                        >
                          {data.bathRoom == "yes" ? data.bathroom_type : "NO"}
                        </p>
                        <p
                          className="detail_card_value"
                          style={{ fontSize: "12px", color: "black" }}
                        >
                          {" "}
                          Bathroom
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div className="detail_box">
                  <p className="detail_key">BathRooms</p>
                  <p className="detail_value">{data.floor_bathroom_count}</p>
                </div>

                {/* <div className="d-flex flex-wrap gap-3 align-items-center">
                {data?.bathRoomDetails.map((data) => {
                  return (
                    <div className="detail_card">
                      <p className="detail_card_value">{data.bathroom_type}</p>
                    </div>
                  );
                })}
              </div> */}
                <div className="detail_box">
                  <p className="detail_key">Kitchen</p>
                  <p className="detail_value">
                    {data.is_kitchen == "1" ? "Yes" : "No"}
                  </p>
                </div>
                {data?.is_kitchen == "1" ? (
                  <div className="d-flex flex-wrap gap-3 align-items-center">
                    <div className="detail_card">
                      <p className="detail_card_value">{data.kitchen_type}</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
        <div className="d-flex gap-2 flex-column">
          <div>
            <p
              style={{
                color: "#3d5ad0",
                fontSize: "20px",
                textTransform: "uppercase",
              }}
            >
              Site Documents
            </p>
          </div>
          {sitePlanDetails?.ec_survey_number != "" ? (
            <div className="d-flex flex-column gap-2">
              <p className="detail_key">EC Survey Number</p>
              <p className="detail_value">
                {sitePlanDetails?.ec_survey_number}
              </p>
            </div>
          ) : (
            <>
              <div className="d-flex flex-column gap-2">
                <p className="detail_key">EC Document</p>
                <p className="detail_value">{sitePlanDetails?.ec_filename}</p>
              </div>
              <div
                className="d-flex flex-wrap gap-3 align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div
                  className="detail_card"
                  onClick={() => {
                    handleFileDownload({
                      extension: sitePlanDetails?.ec_fileextension,
                      file_name: sitePlanDetails?.ec_key,
                      path: sitePlanDetails?.ec_filepath,
                    });
                    setShowDownloadModal(true);
                  }}
                >
                  <p className="detail_card_value">Download</p>
                </div>
              </div>
            </>
          )}
          <div className="d-flex flex-column gap-2">
            <p className="detail_key">Registration Document</p>
            <p className="detail_value">
              {sitePlanDetails?.registration_filename}
            </p>
          </div>
          <div
            className="d-flex flex-wrap gap-3 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div
              className="detail_card"
              onClick={() => {
                handleFileDownload({
                  extension: sitePlanDetails?.registration_fileextension,
                  file_name: sitePlanDetails?.registration_key,
                  path: sitePlanDetails?.registration_filepath,
                });
                setShowDownloadModal(true);
              }}
            >
              <p className="detail_card_value">Download</p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showDownloadModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDownloadModal(false)}
      >
        <Modal.Header className="d-flex align-items-center justify-content-center">
          <div>Are you Sure you want to Download</div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column upload-template-input-box">
            <a href={download?.downloadUrl}>Download</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Index;
