import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import usePreventScroll from "../../CustomHooks/PreventScroll";
import { get } from "../../Utils/Functions/Methods";
import SubscriptionPlan from "../../Utils/General/SubscriptionPlan";

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  const params = useParams();
  usePreventScroll(isVisible);
  const user_key = params.user_key;
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [planValid, setPlanValid] = useState(null);

  useEffect(() => {
    get(`/admin/user/${user_key}/payment_invoice`)
      .then((response) => {
        setInvoiceDetails(response[1]);
      })
      .catch((err) => console.log(err));

    get(`/admin/users/${user_key}/user_details`)
      .then((response) => {
        console.log(response[1]);
        setUserDetails(response[1]);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (invoiceDetails && invoiceDetails.length > 0) {
      const endDate = invoiceDetails[0]?.subscription_end_date;
      setPlanValid(endDate);
    }
  }, [invoiceDetails]);

  return (
    <div className="user-container">
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="user-profile-container">
        <div className="user-photo-container">
          <div className="user-profile-pic"></div>

          <div className="user-plan-container">
            {SubscriptionPlan(userDetails?.subscription_id)}
          </div>
        </div>
        <div className="user-details-container">
          <div className="user-details-box">
            <div className="user-details-topic">User Key</div>
            <div className="user-details-ans">{userDetails?.user_key}</div>
          </div>
          <div className="user-details-box">
            <div className="user-details-topic">Name</div>
            <div className="user-details-ans">
              {userDetails?.firstname + " " + userDetails?.lastname}
            </div>
          </div>
          <div className="user-details-box">
            <div className="user-details-topic">Address</div>
            <div className="user-details-ans">
              {userDetails?.address_1 || userDetails?.address_2
                ? userDetails?.address_1 + " " + userDetails?.address_2
                : "Not Provided"}
            </div>
          </div>
          <div className="user-details-box">
            <div className="user-details-topic">Email</div>
            <div className="user-details-ans">{userDetails?.email}</div>
          </div>
          <div className="user-details-box">
            <div className="user-details-topic">Mobile</div>
            <div className="user-details-ans">{userDetails?.mobile}</div>
          </div>
          <div className="user-details-box">
            <div className="user-details-topic">Plan Valid Till</div>
            <div className="user-details-ans">{planValid}</div>
          </div>
        </div>
      </div>

      {/* Payment Details */}
      <table className="table">
        <thead>
          <tr>
            <th className="table-align">Order Id</th>
            <th>Subscription Name</th>
            <th>Created At</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Amount</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails?.map((data) => {

            return (

              <tr style={{ cursor: "pointer" }}>
                <td>{data?.order_id}</td>
                <td>{SubscriptionPlan(data?.subscription_id)}</td>
                <td>{data?.created_at}</td>
                <td>{data?.subscription_start_date}</td>
                <td>{data?.subscription_end_date}</td>
                <td>{data?.invoice_amount}</td>
                <td>{data?.payment_status}</td>

              </tr>
            );
          })}
        </tbody>
      </table>
      <Sidebar
        className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
          }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </div>
  );
}

export default Index;
