
const GenerateUniqueKey = () => {
  const date = new Date();
  const components = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  ];
  // setUniquekey(components.join(""));
  const uniquekey = components.join("");

  return uniquekey;
};
export default GenerateUniqueKey