import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { get, post, update } from "../../Utils/Functions/Methods";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import SitePlanPreviewTemplate from "../../Components/SiteplanPreviewTemplate";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../Context/AuthContext";
import GenerateUniqueKey from "../../Utils/General/generateUniquekey";
import "./style.css";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  const params = useParams();
  const siteKey = params.siteKey;
  const projectKey = params.projectKey;
  const [sitePlanDetails, setSitePlanDetails] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [alloactedCAD, setAllocatedCAD] = useState("");
  const [cadUsers, setCadUsers] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  usePreventScroll(isVisible);
  const { userAttributes } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    get(`/cad/users`)
      .then((response) => {
        setCadUsers(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, []);

  useEffect(() => {
    get(`/admin/project/${projectKey}/sitePlanRequests/${siteKey}`).then(
      (response) => {
        setSitePlanDetails(response[1]);
      }
    );
  }, []);

  const jobTable = {
    site_key: "",
    assigned_to: "",
    assigned_by: "",
    modified_at: "",
    modified_by: "",
    created_at: "",
    job_status: "",
    project_folder_key: "",
    file_upload_status: "pending",
  };

  const accepted = async () => {
    const temp = { request_status: "accepted" };

    await update(`/admin/sitePlanRequests/${siteKey}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          console.log("result success", res);
          setRefreshData(!refreshData);
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));

    const tempData = {
      ...jobTable,
      assigned_to: alloactedCAD,
      site_key: siteKey,
      assigned_by: userAttributes?.sub,
      job_status: "pending",
      project_folder_key: GenerateUniqueKey(),
    };
    await post(`/admin/jobTable`, tempData)
      .then(([status, res]) => {
        if (status === 200) {
          console.log("result success", res);
          setRefreshData(!refreshData);
          navigate("/designRequests/pending");
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };
  const decline = async () => {
    const temp = { request_status: "rejected" };
    await update(`/admin/sitePlanRequests/${siteKey}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          console.log("result success", res);
          setRefreshData(!refreshData);
          navigate("/designRequests/pending");
        } else {
          alert("Something went wrong");
          console.log(status);
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      </div>
      <div>
        <div className="designers-list">
          <select
            className="form-select"
            name="CAD-Designers-List"
            id="CAD-Designers-List"
            onChange={(e) => {
              setAllocatedCAD(e?.target.value);
            }}
          >
            <option value="">Please Select Cad User</option>
            {cadUsers?.map((data) => {
              return (
                <option value={data?.user_key}>
                  {data?.firstname + " " + data?.lastname}
                </option>
              );
            })}
          </select>
        </div>
        <div className="d-flex gap-2 m-2">
          {alloactedCAD != "" ? (
            <Button
              variant="outline-success"
              onClick={() => {
                accepted();
              }}
              id="Accepted"
            >
              Assign Job
            </Button>
          ) : (
            <></>
          )}
          <Button variant="outline-danger" onClick={() => decline()}>
            Decline Job
          </Button>{" "}
        </div>
      </div>
      <SitePlanPreviewTemplate sitePlanDetails={sitePlanDetails} />
      <Sidebar
        className={`sidebar-container ${isVisible ? "sidebar-visible" : "sidebar-hide"
          }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
