import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import "./style.css";
import { Auth } from "aws-amplify";
import { post } from "../../../Utils/Functions/Methods";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Logo from "../../../Assets/Logo/Consdroid-logo.png";

export default function Index() {
  const navigate = useNavigate();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repassword: "",
  };
  const [values, setValues] = useState(initialValues);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repasswordError, setRepasswordError] = useState("");
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [userData, setUserData] = useState(initialValues);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const tempValues = { ...values, [name]: value };
    setValues(tempValues);
    if (e.target.name == "email") {
      if (!tempValues?.email) {
        setEmailError("Email is Required");
      } else if (!emailRegex.test(tempValues?.email)) {
        setEmailError("Please Enter a Valid Email");
      } else {
        setEmailError("");
      }
    }
    if (e.target.name == "firstName") {
      if (!tempValues?.firstName) {
        setFirstNameError("First Name is Required");
      } else if (tempValues?.firstName.length < 2) {
        setFirstNameError("Minimum 3 Characters Required");
      } else {
        setFirstNameError("");
      }
    }
    if (e.target.name == "password") {
      if (!tempValues?.password) {
        setPasswordError("Password is Required");
      } else if (tempValues?.password.length < 5) {
        setPasswordError("Minimum 5 Characters Required");
      } else {
        setPasswordError("");
      }
    }

    if (e.target.name == "repassword") {
      if (!tempValues?.repassword) {
        setRepasswordError("Please Re Enter the password");
      } else if (tempValues?.repassword != tempValues?.password) {
        setRepasswordError("Password and Reenter Password Must be Same");
      } else {
        setRepasswordError("");
      }
    }
  };

  // ! Disable button using the Error values
  // useEffect(() => {
  //   setIsDisabled(!(emailError=="" && firstNameError==""&& passwordError=="" && repasswordError==""));
  // }, [
  //   emailError,passwordError,repasswordError,firstNameError
  // ]);

  useEffect(() => {
    setIsDisabled(
      !(
        emailRegex.test(values?.email) &&
        values?.firstName.length > 4 &&
        values?.password.length > 4 &&
        values?.repassword == values?.password
      )
    );
  }, [values]);

  return (
    <div className="signuppage-container">
      <div className="signup-box">
        <img src={Logo} height="30px"></img>
        <div className="signup-box-title">Sign Up</div>
        <div className="signup-box-input">
          <input
            placeholder="First Name"
            className="signup-box-input-field"
            name="firstName"
            onChange={handleChange}
            value={values?.firstName}
          ></input>
          <p className="signup-auth-error">{firstNameError}</p>
        </div>
        <div className="signup-box-input">
          <input
            placeholder="Last Name"
            className="signup-box-input-field"
            name="lastName"
            onChange={handleChange}
            value={values?.lastName}
          ></input>
        </div>
        <div className="signup-box-input">
          <input
            placeholder="E-mail"
            className="signup-box-input-field"
            name="email"
            onChange={handleChange}
            value={values?.email}
          ></input>
          <p className="signup-auth-error">{emailError}</p>
        </div>
        <div className="signup-box-input">
          <input
            placeholder="Password"
            className="signup-box-input-field"
            name="password"
            onChange={handleChange}
            value={values?.password}
            type={showPassword ? "text" : "password"}
          ></input>
          {showPassword ? (
            <Eye
              className="login-eye"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              onMouseDown={(e) => e.preventDefault}
            ></Eye>
          ) : (
            <EyeSlash
              className="login-eye"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              onMouseDown={(e) => e.preventDefault}
            ></EyeSlash>
          )}
          <p className="signup-auth-error">{passwordError}</p>
        </div>
        <div className="signup-box-input">
          <input
            placeholder="Re Enter Password"
            className="signup-box-input-field"
            name="repassword"
            onChange={handleChange}
            value={values?.repassword}
            type={showRePassword ? "text" : "password"}
          ></input>
          {showRePassword ? (
            <Eye
              className="login-eye"
              onClick={() => {
                setShowRePassword(!showRePassword);
              }}
              onMouseDown={(e) => e.preventDefault}
            ></Eye>
          ) : (
            <EyeSlash
              className="login-eye"
              onClick={() => {
                setShowRePassword(!showRePassword);
              }}
              onMouseDown={(e) => e.preventDefault}
            ></EyeSlash>
          )}
          <p className="signup-auth-error">{repasswordError}</p>
        </div>
        <button
          className="signup-box-btn"
          onClick={() => {
            navigate("/");
          }}
          disabled={isDisabled}
        >
          <div>Sign Up</div>
        </button>
        <div className="d-flex">
          <div className="mx-1" style={{ fontSize: "14px" }}>
            Already have an Account?
          </div>
          <div
            className="mx-1"
            style={{ fontSize: "14px", cursor: "pointer", color: "#3D5AD0" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
}
