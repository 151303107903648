import React from "react";
import "./style.css";
import { StateContext } from "../../Context/StateContext";
import { useContext, useState } from "react";
import SiteDetails from "./SiteDetails";
import ProjectFiles from "./ProjectFiles";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Button, CloseButton, Modal, ModalBody } from "react-bootstrap";
import { get, update } from "../../Utils/Functions/Methods";
import { useEffect } from "react";
import { AuthContext } from "../../Context/AuthContext";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { useNavigate, useParams } from "react-router";
import SitePlanPreviewTemplate from "../../Components/SiteplanPreviewTemplate";
import usePreventScroll from "../../CustomHooks/PreventScroll";

function Index() {
  // reassign modal
  const navigate = useNavigate();
  const [reAssignJobModal, setReAssignJobModal] = useState(false);
  const [alloactedCAD, setAllocatedCAD] = useState("");
  const [cadUsers, setCadUsers] = useState(null);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const { userAttributes, refreshDesignPending, setRefreshDesignPending } =
    useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const { individualJobDetails } = useContext(StateContext);
  const [cadUser, setCadUser] = useState(null);

  const [customerData, setCustomerData] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);

  const params = useParams();
  const jobId = params.jobId;
  usePreventScroll(isVisible);

  useEffect(() => {
    get(`/cad/users`)
      .then((response) => {
        setCadUsers(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });

    get(`/admin/jobTable/${jobId}`)
      .then((response) => {
        setJobDetails(response[1]);
      })
      .catch((error) => {
        console.log("JobData", error);
      });
  }, []);

  const [currentButton, setCurrentButton] = useState("button1");

  const unSelectedClass = "btn side-button3";
  const selectedClass = "side-button-3";
  const [details, SetDetails] = useState("siteDetails");
  const projectDetails = () => {
    switch (details) {
      case "siteDetails":
        return (
          <SitePlanPreviewTemplate
            sitePlanDetails={{
              ...jobDetails?.site_details,
              floorDetails: jobDetails?.floorDetails,
            }}
          />
        );
      case "projectFiles":
        return (
          <ProjectFiles
            jobDetails={individualJobDetails}
            fileStatus={individualJobDetails?.file_upload_status}
          />
        );
    }
  };

  if (jobDetails?.job_status === "completed") {
    var bgColor = "#93FAA5";
  } else if (jobDetails?.job_status === "pending") {
    var bgColor = "#FF6258";
  } else {
    var bgColor = "#3D5AD0";
  }
  const reassignJob = async (data) => {
    const tempData = {
      ...data,
      assigned_to: alloactedCAD,
      modified_by: userAttributes?.sub,
      job_status: "pending",
    };
    await update(`/admin/jobTable/${data.job_id}`, tempData)
      .then(([status, res]) => {
        if (status === 200) {
          setShowReassignModal(false);
          alert("Success");
        } else {
          alert("Something went wrong");
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateJobStatus = async (data) => {
    const tempData = {
      ...jobDetails,
      job_status: "completed",
    };
    try {
      await update(`/admin/jobTable/${jobDetails.job_id}`, tempData);
      await update(`/admin/sitePlanRequests/${jobDetails?.site_key}`, {
        request_status: "completed",
      });
      navigate("/designRequests/accepted/completedJobs");
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <Navbar sidebarOnClickOpen={() => setIsVisible(true)} />
      <div className="d-flex align-items-center justify-content-center flex-column gap-5">
        <div className="design-job-details-card">
          <div className="content-designAccept">
            <div>
              <div className="d-flex align-items-center justify-content-between pb-3 ">
                <div className="fw-bold  "> JOB ID: {jobDetails?.job_id}</div>
                <div
                  style={{
                    backgroundColor: bgColor,
                    width: "max-content",
                    padding: "5px",
                  }}
                  className="text-center rounded fw-bold "
                >
                  {jobDetails?.job_status}
                </div>
              </div>
              <div className="d-flex justify-content-between flex-column gap-3">
                <div className="">
                  ASSIGNED TO: {jobDetails?.assigned_to_username}
                </div>
                <div className="">
                  ASSIGNED BY: {jobDetails?.assigned_by_username}
                </div>
                <div className="">
                  {" "}
                  CREATED BY:
                  {jobDetails?.created_by_username}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex align-items-center justify-content-center ">
              {jobDetails?.job_status == "pending" || "ongoing" ? (
                <div
                  onClick={() => setShowReassignModal(!showReassignModal)}
                  className="reassign-job fw-bold "
                >
                  REASSIGN-JOB
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center justify-content-center ">
              {jobDetails?.file_upload_status == "uploaded" ? (
                <div
                  className="reassign-job fw-bold "
                  onClick={updateJobStatus}
                >
                  Completed
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          show={showReassignModal}
          centered
          onHide={() => setShowReassignModal(false)}
        >
          <ModalBody>
            <div className="d-flex align-items-center justify-content-center flex-column gap-3">
              <div>
                <select
                  className="form-select"
                  name="CAD-Designers-List"
                  id="CAD-Designers-List"
                  onChange={(e) => {
                    setAllocatedCAD(e?.target.value);
                  }}
                >
                  <option value="">Please Select Cad User</option>
                  {cadUsers?.map((data) => {
                    return (
                      <option value={data?.user_key}>
                        {data?.firstname + " " + data?.lastname}
                      </option>
                    );
                  })}
                </select>
              </div>
              {alloactedCAD != "" ? (
                <Button
                  variant="success"
                  onClick={() => {
                    reassignJob(individualJobDetails);
                  }}
                  id="Accepted"
                >
                  Assign Job
                </Button>
              ) : (
                <></>
              )}
            </div>
          </ModalBody>
        </Modal>

        <div className="d-flex align-items-center justify-content-center gap-3">
          <div
            onClick={() => {
              SetDetails("siteDetails");
              setCurrentButton("button1");
            }}
            className={`d-flex align-items-center justify-content-center fw-bold p-2 ${
              currentButton == "button1" ? selectedClass : unSelectedClass
            }
          `}
            id="button1"
          >
            SITE DETAILS
          </div>

          <div
            onClick={() => {
              setCurrentButton("button2");
              SetDetails("projectFiles");
            }}
            className={` d-flex align-items-center justify-content-center  mx-3  fw-bold p-2 ${
              currentButton == "button2" ? selectedClass : unSelectedClass
            }
          `}
            id="button2"
          >
            PROJECT FILES
          </div>
        </div>
        {projectDetails()}
      </div>

      <Sidebar
        className={`sidebar-container ${
          isVisible ? "sidebar-visible" : "sidebar-hide"
        }`}
        sidebarOnClickClose={() => setIsVisible(false)}
      />
    </>
  );
}

export default Index;
