import React, { createContext, useState, useEffect } from "react";

export const StateContext = createContext(null);

export const StateProvider = ({ children }) => {

    const [individualJobDetails, setIndividualJobDetails] = useState(null)

    return (
        <StateContext.Provider
            value={{
                individualJobDetails,
                setIndividualJobDetails
            }}
        >
            {children}
        </StateContext.Provider>
    );









}


