import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../Context/AuthContext";
import "./style.css";

function Index() {
  const navigate = useNavigate();

  const initialValues = { email: "", password: "", otp: "" };
  const [values, setValues] = useState(initialValues);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [code, setCode] = useState(null);
  const { forgotPassword } = useContext(AuthContext);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    if (e.target.name == "email")
      if (!values?.email) {
        setEmailError("Email is Required");
      } else if (!emailRegex.test(values?.email)) {
        setEmailError("Please Enter a Valid Email");
      } else {
        setEmailError("");
      }

    if (e.target.name == "password") {
      if (!values?.password) {
        setPasswordError("Password is Required");
      } else if (values?.password.length < 4) {
        setPasswordError("Minimum 5 Characters Required");
      } else {
        setPasswordError("");
      }
    }

    if (e.target.name == "otp") {
      if (!values?.otp) {
        setOtpError("OTP is Required");
      } else if (values?.otp.length < 5) {
        setOtpError("6 Characters Required");
      } else {
        setOtpError("");
      }
    }
  };
  const onSubmit = async () => {
    if (values?.email !== "") {
      if (values?.otp !== "") {
        if (values?.password !== "") {
          await forgotPassword
            .setPassword(values?.email, values?.otp, values?.password)
            .then((_) => {
              // console.log("password changed > ", _);
              navigate("/");
            })
            .catch((err) => {
              console.log("error > ", err);
            });
        }
      }
    }
  };

  useEffect(() => {
    setIsDisabled(
      !(
        emailRegex.test(values?.email) &&
        values?.password &&
        values?.password.length > 4 &&
        values?.otp.length > 5
      )
    );
  }, [values]);

  return (
    <div className="setpass-container">
      <div className="setpass-box">
        <div className="setpass-box-title">Set Password</div>
        <div className="setpass-box-input">
          <input
            name="email"
            placeholder="Email"
            className="setpass-box-input-field"
            onChange={handleChange}
            value={values?.email}
          ></input>
          <p className="setpass-auth-error">{emailError}</p>
        </div>

        <div className="setpass-box-input">
          <input
            name="otp"
            placeholder="OTP"
            className="setpass-box-input-field"
            onChange={handleChange}
            value={values?.otp}
          ></input>
          <p className="setpass-auth-error">{otpError}</p>
        </div>

        <div className="setpass-box-input">
          <input
            name="password"
            placeholder="Password"
            className="setpass-box-input-field"
            onChange={handleChange}
            value={values?.password}
          ></input>
          <p className="setpass-auth-error">{passwordError}</p>
        </div>
        <button
          className="setpass-box-btn"
          disabled={isDisabled}
          onClick={() => {
            onSubmit();
          }}
        >
          <div>Reset Password</div>
        </button>
      </div>
    </div>
  );
}

export default Index;
