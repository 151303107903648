import React from "react";
import "./style.css";
import { useState } from "react";
import ProjectDetails from "./Component/ProjectFiles";
import SiteDetails from "./Component/SiteDetails";
import { Button } from "react-bootstrap";
import { get, update } from "../../../../../Utils/Functions/Methods";

export default function Index() {
  const [designPending, setDesignPending] = useState("siteDetails");

  const designPendingDetails = () => {
    switch (designPending) {
      case "projectDetails":
        return <ProjectDetails />;
      case "siteDetails":
        return <SiteDetails />;
    }
  };

  const [individualJobData, setIndividualJobData] = useState(null);
  const [cadUsers, setCadUsers] = useState(null);
  const [cadUser, setCadUser] = useState(null);
  const [popupMenu, setPopupMenu] = useState(false);
  const viewJobData = async (data) => {
    setPopupMenu(true);
    setIndividualJobData(data);

    await get(`/cad/users/${data?.assigned_to}`)
      .then((response) => {
        setCadUser(response[1]);
      })
      .catch((error) => {
        console.log("cad User Data Fetch Error", error);
      });

    await get(`/cad/users`)
      .then((response) => {
        setCadUsers(response[1]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
    // ! HAVE TO GET ADMIN DATA AND DISPLAY IT OVER HERE
  };

  return (
    <>
      <div className="pending-jobdetails">
        {individualJobData ? (
          <div className="d-flex">
            <div>
              <div className="d-flex number-content">
                <div className="contents-view">Assinged To:</div>
                <div className="ms-2 mail-main">
                  {cadUser?.firstname + " " + cadUser?.lastname}
                </div>
              </div>
              <div className="d-flex number-content">
                <div className="contents-view">Assinged By: </div>
                <div className="ms-2 mail-main">
                  {individualJobData.assigned_by}
                </div>
              </div>

              <div className="d-flex number-content">
                <div className="contents-view">Created At:</div>
                <div className="ms-2 mail-main">
                  {individualJobData.created_at}
                </div>
              </div>

              <div className="d-flex number-content">
                <div className="contents-view">Job Status:</div>
                <div className="ms-2 mail-main">
                  {individualJobData.job_status}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-5 btns-details">
        <Button onClick={() => setDesignPending("siteDetails")}>
          SITEDETAILS
        </Button>
        <Button
          className="mx-5"
          onClick={() => setDesignPending("projectDetails")}
        >
          PROJECTFILES
        </Button>
        {designPendingDetails()}
      </div>
    </>
  );
}
