import React, { useState, useEffect } from "react";
import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  Navbar,
  Row,
} from "react-bootstrap";
import { get, update } from "../../../Utils/Functions/Methods";
import OnGoingJob from "./OnGoingCard";
import PendingJob from "./PendingCard";
import CompletedJob from "./CompletedCard";
import "./style.css";
// import AcceptedStatus from "../../Components/AcceptedStatus";
// import RejectedStatus from "../../Components/PendingStatus";
// import PendingStatus from "../../Components/PendingStatus";
import { useNavigate, useParams } from "react-router-dom";

export default function Index() {
  const params = useParams();
  const jobStatus = params.jobStatus;

  const [individualData, setIndividualData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [countDetails, setCountDetails] = useState(null);
  const navigate = useNavigate();

  type BtnProp = { label: string; value: string };
  const btns: BtnProp[] = [
    { label: "OnGoingJob", value: "OnGoingJob" },
    { label: "PendingJob", value: "PendingJob" },
    { label: "CompletedJob", value: "CompletedJob" },
  ];

  const [currentView, setCurrentView] = useState("3d");

  const renderView = () => {
    switch (jobStatus) {
      case "ongoingJob":
        return (
          <OnGoingJob
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        );
      case "pendingJob":
        return (
          <PendingJob
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        );
      case "completedJob":
        return (
          <CompletedJob
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        );
      default:
        return (
          <OnGoingJob
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        );
    }
  };

  const accepted = async (data) => {
    const temp = { ...data, request_status: "accepted" };
    await update(`/admin/sitePlanRequests/${data.site_key}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          setRefreshData(!refreshData);
        } else {
          alert("Something went wrong");
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };
  const decline = async (data) => {
    const temp = { ...data, request_status: "rejected" };
    await update(`/admin/sitePlanRequests/${data.site_key}`, temp)
      .then(([status, res]) => {
        if (status === 200) {
          setRefreshData(!refreshData);
        } else {
          alert("Something went wrong");
          console.log("result fail", res);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    get(`/admin/count_status`)
      .then((response) => {
        setCountDetails(response[1][0]);
      })
      .catch((error) => {
        console.log("profile fetch error", error);
      });
  }, []);
  return (
    <>
      <div>
        <div className="d-flex dashboard-cards">
          <div
            className="dash-card10"
            onClick={() => navigate("/designRequests/accepted/ongoingJob")}
          >
            <div className="status-10">Ongoing</div>
            <div className="number10">
              {" "}
              {countDetails?.ongoingJobs < 10
                ? "0" + countDetails?.ongoingJobs
                : countDetails?.ongoingJobs}
            </div>
            <div className="view-status1">View All</div>
          </div>
          <div
            className="dash-card20"
            onClick={() => navigate("/designRequests/accepted/pendingJob")}
          >
            <div className="status-10">Pending </div>
            <div className="number10">
              {" "}
              {countDetails?.pendingJobs < 10
                ? "0" + countDetails?.pendingJobs
                : countDetails?.pendingJobs}
            </div>
            <div className="view-status1">View All</div>
          </div>
          <div
            className="dash-card30"
            onClick={() => navigate("/designRequests/accepted/completedJob")}
          >
            <div className="status-10">Completed</div>
            <div className="number30">
              {" "}
              {countDetails?.completedJobs < 10
                ? "0" + countDetails?.completedJobs
                : countDetails?.completedJobs}
            </div>
            <div className="view-status1">View All</div>
          </div>
        </div>
      </div>
      <br />

      {/* <div className="d-flex card-accept"> */}
      <div className="mt-5">{renderView()}</div>
      {/* </div> */}
    </>
  );
}
