import React from "react";
import "./App.css";
import Router from "./Route";
import { AuthProvider } from "./Context/AuthContext";
import { StateProvider } from "./Context/StateContext";

export default function App() {
  return (
    <AuthProvider>
      <StateProvider>
        <Router />
      </StateProvider>
    </AuthProvider>
  );
}
